import React, { useState, useEffect, useCallback } from 'react';
import { TreeSelect } from 'antd';

interface SelectValue {
  label: string;
  value: string;
}

interface UserSelectionProps {
  users: { email: string; azureObjectId: string }[];
  selectedUsers: SelectValue[];
  handleChangeSelectedUsers: (selectedUsers: SelectValue[]) => void;
}

const UserSelection: React.FC<UserSelectionProps> = ({
  users,
  selectedUsers,
  handleChangeSelectedUsers,
}) => {
  const [internalSelectedUsers, setInternalSelectedUsers] = useState<SelectValue[]>(selectedUsers);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    setInternalSelectedUsers(selectedUsers);
  }, [selectedUsers]);

  const groupUsersByDomain = useCallback((users: { email: string; azureObjectId: string }[]) => {
    type GroupedUsers = {
      [domain: string]: { email: string; azureObjectId: string }[];
    };

    const grouped: GroupedUsers = users.reduce((acc: GroupedUsers, user) => {
      const domain = user.email.split('@')[1];
      if (!acc[domain]) {
        acc[domain] = [];
      }
      acc[domain].push(user);
      return acc;
    }, {});

    const sortedDomains = Object.keys(grouped).sort();

    return sortedDomains.flatMap((domain) => {
      const usersInDomain = grouped[domain];
      const sortedUsersInDomain = usersInDomain.sort((a, b) => a.email.localeCompare(b.email));

      if (sortedUsersInDomain.length === 1) {
        const user = sortedUsersInDomain[0];
        return {
          title: user.email,
          value: user.azureObjectId,
          key: user.azureObjectId,
        };
      } else {
        return {
          title: domain,
          value: domain,
          key: domain,
          children: sortedUsersInDomain.map((user) => ({
            title: user.email,
            value: user.azureObjectId,
            key: user.azureObjectId,
          })),
        };
      }
    });
  }, []);

  const handleChange = (value: SelectValue[]) => {
    const selected = value.map((val) => ({
      label: val.label,
      value: val.value,
    }));
    setInternalSelectedUsers(selected);
    if (!isDropdownVisible) {
      handleChangeSelectedUsers(selected);
    }
  };

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownVisible(visible);
    if (!visible) {
      handleChangeSelectedUsers(internalSelectedUsers);
    }
  };

  return (
    <TreeSelect
      treeData={groupUsersByDomain(users)}
      value={internalSelectedUsers}
      labelInValue={true}
      onChange={handleChange}
      treeCheckable={true}
      placeholder="Bitte Benutzer auswählen"
      allowClear={true}
      style={{ marginTop: '12px', width: '555px' }}
      showSearch
      filterTreeNode={(inputValue, treeNode) => {
        const title = treeNode.title;
        if (typeof title === 'string') {
          return title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }
        return false;
      }}
      onDropdownVisibleChange={handleDropdownVisibleChange}
    />
  );
};

export default UserSelection;
