import React, { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Dropdown, Button, MenuProps, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { loginRequest } from '../../authConfig';

const UserMenu: React.FC = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [userMenuItems, setUserMenuItems] = React.useState<MenuProps>();

  const handleLogin = () => {
    if (inProgress === 'none') {
      instance.loginRedirect(loginRequest);
    } else {
      message.warning(
        'Eine Interaktion ist derzeit im Gange. Bitte warten Sie, bis sie abgeschlossen ist, oder aktualisieren Sie die Seite.'
      );
    }
  };

  const handleLogout = () => {
    if (inProgress === 'none') {
      instance.logoutRedirect();
    } else {
      message.warning(
        'Eine Interaktion ist derzeit im Gange. Bitte warten Sie, bis sie abgeschlossen ist, oder aktualisieren Sie die Seite.'
      );
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      setUserMenuItems({
        items: [
          {
            type: 'group',
            key: '1',
            label: instance.getActiveAccount()?.username,
            children: [
              {
                key: '2',
                label: (
                  <Button type="primary" block onClick={handleLogout}>
                    Abmelden
                  </Button>
                ),
              },
            ],
          },
        ],
      });
    }
    // console.log("active account: ", instance.getActiveAccount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, instance, inProgress]);

  return isAuthenticated ? (
    <Dropdown menu={userMenuItems} placement="bottomLeft" arrow>
      <Button shape="circle" icon={<UserOutlined />} />
    </Dropdown>
  ) : (
    <Button type="primary" onClick={handleLogin}>
      Anmelden
    </Button>
  );
};

export default UserMenu;
