/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: enable no-unused-vars
import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, message, Modal, Select, Spin, Typography } from 'antd';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { apiUrl } from '../../common/url';
import FacilitySelection from '../actionPlanning/FacilitySelection';

interface RecalculationProps {
  client: { id: string; name?: string; token: string; permissions: string[] };
}

const Recalculation: React.FC<RecalculationProps> = ({ client }) => {
  const [loading, setLoading] = useState(false);
  const [loadingSelectedKeys, setLoadingSelectedKeys] = useState(false);
  const [dataCollYear, setDataCollYear] = useState<number>();
  const [selectedDataCollEntries, setSelectedDataCollEntries] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [statusRecalculation, setStatusRecalculation] = useState<{
    calc_selected_keys: string[];
    calc_status: string;
    calc_status_message: string;
    calc_finished_at: string;
    calc_duration: string;
  }>();
  const [lastStatusUpdate, setLastStatusUpdate] = useState<string | null>(null);
  const [year, setYear] = useState<number>();
  const [years, setYears] = useState<number[]>([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/b2zero/${client.id}/get_data_coll_years`, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        const years = response.data;
        setYears(years);
        if (years.length > 0) {
          setYear(Math.max(...years));
        }
      })
      .catch((error) => {
        message.error('Fehler beim Abrufen der verfügbaren Startjahre: ' + error.message);
        Sentry.withScope((scope) => {
          scope.setLevel('error');
          scope.setExtra('hint', 'Fehler beim Abrufen der verfügbaren Startjahre');
          Sentry.captureException(error);
        });
      });

    axios
      .get(`${apiUrl}/b2zero/${client.id}/get_start_year_calc`, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        setYear(response.data.year);
      })
      .catch((error) => {
        message.error(
          'Fehler beim Abrufen des Startjahres für die Neuberechnung: ' + error.message
        );
        Sentry.withScope((scope) => {
          scope.setLevel('error');
          scope.setExtra('hint', 'Fehler beim Abrufen des Startjahres für die Neuberechnung');
          Sentry.captureException(error);
        });
      });
  }, [client.id, client.token]);

  const handleChangeYear = (value: number) => {
    setYear(value);
  };

  const fetchRecalculationStatus = () => {
    axios
      .get(`${apiUrl}/b2zero/${client.id}/get_recalculation_status`, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        const data = response.data;
        data.calc_finished_at = data.calc_finished_at
          ? new Date(data.calc_finished_at).toLocaleString('de-DE') + ' Uhr'
          : null;
        // Check if calc_selected_keys is a string and convert it to an array if necessary
        if (typeof data.calc_selected_keys === 'string') {
          data.calc_selected_keys = data.calc_selected_keys.replace(/[{}]/g, '').split(',');
        }
        setStatusRecalculation(data);
        setLastStatusUpdate(new Date().toLocaleString('de-DE') + ' Uhr');
      })
      .catch((error) => {
        message.error('Fehler beim Abrufen des Neuberechnungsstatus: ' + error.message, 3);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Abrufen des Neuberechnungsstatus: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  useEffect(() => {
    // Call immediately
    fetchRecalculationStatus();
    // Then call every 30 seconds
    const intervalId = setInterval(fetchRecalculationStatus, 30000); // 30000 milliseconds = 30 seconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.id, client.token]);

  const successModal = () => {
    Modal.success({
      title: 'Neuberechnung erfolgreich ausgelöst',
      content:
        'Die Neuberchnung wurde erfolgreich ausgelößt. Die Ergebnisse können sobald die Neuberechnung abgeschlossen ist im b2zero Bericht eingesehen werden.',
    });
  };

  const errorModal = (message: string, statusCode: number) => {
    Modal.error({
      title: 'Neuberechnung fehlgeschlagen',
      content: `Die Neuberechnung ist fehlgeschlagen (Status: ${statusCode}). Fehler: ${message}. Bitte kontaktieren Sie den Support.`,
    });
  };

  const handleRecalculate = () => {
    setLoading(true);
    axios
      .post(
        `${apiUrl}/b2zero/${client.id}/recalculate`,
        {
          year: year,
        },
        { headers: { Authorization: `Bearer ${client.token}` } }
      )
      .then(() => {
        successModal();
        setLoading(false);
      })
      .catch((error) => {
        const message = error.response?.data?.message || 'Unbekannter Fehler';
        const statusCode = error.response?.status || 500;
        console.log(`Error ${statusCode}: ${message}`);
        errorModal(message, statusCode);
        setLoading(false);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Neuberechnung fehlgeschlagen');
          scope.setExtra('statusCode', statusCode);
          scope.setExtra('errorMessage', message);
          Sentry.captureException(error);
        });
      });
  };

  const handleRecalculateSelection = () => {
    setLoadingSelectedKeys(true);
    axios
      .post(
        `${apiUrl}/b2zero/${client.id}/recalculate_objects`,
        {
          year: dataCollYear,
          keys: selectedKeys,
        },
        { headers: { Authorization: `Bearer ${client.token}` } }
      )
      .then(() => {
        successModal();
        setLoadingSelectedKeys(false);
      })
      .catch((error) => {
        const message = error.response?.data?.message || 'Unbekannter Fehler';
        const statusCode = error.response?.status || 500;
        console.log(`Error ${statusCode}: ${message}`);
        errorModal(message, statusCode);
        setLoadingSelectedKeys(false);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Neuberechnung fehlgeschlagen');
          scope.setExtra('statusCode', statusCode);
          scope.setExtra('errorMessage', message);
          Sentry.captureException(error);
        });
      });
  };

  return (
    <>
      <Typography.Title level={2}>Neuberechnung für {client.name}</Typography.Title>
      <Typography.Title level={3}>Status letzte Neuberechnung</Typography.Title>
      <Button type="primary" onClick={fetchRecalculationStatus} style={{ marginBottom: '10px' }}>
        Status aktualisieren
      </Button>
      {lastStatusUpdate ? (
        <Typography.Paragraph>
          <b>Letzte Aktualisierung:</b> {lastStatusUpdate} <br />
          {statusRecalculation?.calc_status ? (
            <>
              {statusRecalculation?.calc_finished_at && (
                <>
                  <b>Letzte abgeschlossene Neuberechnung:</b>{' '}
                  {statusRecalculation?.calc_finished_at} <br />
                </>
              )}
              {statusRecalculation?.calc_selected_keys ? (
                <>
                  <b>Neuberechnung für ausgewählte Anlagen:</b>{' '}
                  {statusRecalculation?.calc_selected_keys.join(', ')}
                </>
              ) : (
                <b>Neuberechnung für alle Anlagen</b>
              )}
              <br />
              <b>Status:</b> {statusRecalculation?.calc_status} <br />
              {statusRecalculation?.calc_status_message && (
                <>
                  <b>Statusmeldung:</b> {statusRecalculation?.calc_status_message}
                </>
              )}
              {statusRecalculation?.calc_duration && (
                <>
                  <br />
                  <b>Berechnungsdauer:</b> {statusRecalculation?.calc_duration.split('.')[0]}
                </>
              )}
            </>
          ) : (
            <Alert
              message="Statusmeldungen sind ab der nächsten Neuberechnung verfügbar."
              type="info"
            />
          )}
        </Typography.Paragraph>
      ) : (
        <>
          <br />
          <Spin style={{ marginLeft: '60px' }} />
        </>
      )}

      {client.permissions.includes('recalculate') && (
        <>
          <Typography.Title level={3}>Neuberechnung für alle Anlagen</Typography.Title>
          <Form.Item label="Startjahr" style={{ marginBottom: 0 }}>
            <Select
              placeholder="Bitte wählen Sie ein Jahr"
              style={{ width: 200, marginBottom: 12 }}
              onChange={handleChangeYear}
              value={year}
            >
              {years.map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type="primary"
            onClick={() =>
              Modal.confirm({
                title: `Wollen Sie wirklich eine Neuberechnung des Berichts für ${client.name} auslösen und den bisherigen Bericht überschreiben?`,
                content:
                  'Der aktuelle Bericht wird damit überschrieben. Es kann nur eine Neuberechnung gleichzeitig ausgeführt werden.',
                okText: 'Ja, starten',
                cancelText: 'Nein, abbrechen',
                onOk() {
                  handleRecalculate();
                },
                onCancel() {
                  // Handle cancel action if needed
                },
              })
            }
            loading={loading}
          >
            Berechnung starten
          </Button>
        </>
      )}
      {client.permissions.includes('recalculate_objects') && (
        <>
          <Typography.Title level={3}>Neuberechnung für ausgewählte Anlagen</Typography.Title>
          <FacilitySelection
            client={client}
            dataCollYear={dataCollYear}
            setDataCollYear={setDataCollYear}
            selectedDataCollEntries={selectedDataCollEntries}
            setSelectedDataCollEntries={setSelectedDataCollEntries}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
          />
          <br />
          <Button
            type="primary"
            onClick={() => {
              Modal.confirm({
                title: `Wollen Sie wirklich eine Neuberechnung des Berichts für ${client.name} auslösen, diesen auf die ausgewählten Anlagen begrenzen und den bisherigen Bericht damit überschreiben?`,
                content: `Es kann nur eine Neuberechnung gleichzeitig ausgeführt werden. Der Bericht wird ausschließlich die Daten für die folgenden Anlagen beinhalten: ${selectedDataCollEntries.join(', ')}.`,
                okText: 'Ja, starten',
                cancelText: 'Nein, abbrechen',
                onOk() {
                  handleRecalculateSelection();
                },
                onCancel() {
                  // Handle cancel action if needed
                },
              });
            }}
            loading={loadingSelectedKeys}
            style={{ marginTop: '12px' }}
            disabled={selectedKeys.length === 0}
          >
            Berechnung starten
          </Button>
        </>
      )}
    </>
  );
};

export default Recalculation;
