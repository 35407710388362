import React from 'react';
import { Card, Form, Typography } from 'antd';
import { FilterOptions } from './CrremGuidelinesDashboard';
import FilterFormItem from './FilterFormItem';

interface AssetPathwayFiltersProps {
  filterOptions?: FilterOptions;
  selectedFilters: {
    version?: number;
    assetClass?: string[];
    pathway?: number[];
    yearEnd?: number[];
    referenceArea?: string[];
    country?: string[];
  };
  onFilterChange: (
    value: any,
    filterName: 'version' | 'assetClass' | 'pathway' | 'yearEnd' | 'referenceArea' | 'country'
  ) => void;
}

const FILTER_TOOLTIPS = {
  assetClass: (
    <>Wählen Sie eine oder mehrere Assetklassen aus (z.B. Wohngebäude, Bürogebäude, Hotels).</>
  ),
  pathway: (
    <>
      Wählen Sie die gewünschten Klimapfade:
      <br />• 1,5°C - Pariser Klimaziel
      <br />• 2°C - Alternatives Klimaszenario
    </>
  ),
  referenceArea: (
    <>
      Wählen Sie die Bezugsfläche:
      <br />• Wohnfläche (nur für Multi Residential)
      <br />• IPMS2 (Nutzfläche, für alle Assetklassen)
    </>
  ),
  yearEnd: <>Wählen Sie das Zieljahr für die Dekarbonisierung.</>,
  version: <>Wählen Sie die Version der CRREM Pfade.</>,
  country: <>Wählen Sie Länder aus, für die Sie die Pfade sehen möchten.</>,
} as const;

const AssetPathwayFilters: React.FC<AssetPathwayFiltersProps> = ({
  filterOptions,
  selectedFilters,
  onFilterChange,
}) => {
  return (
    <Card
      title={<Typography.Title level={4}>Filter</Typography.Title>}
      style={{ marginBottom: 24 }}
    >
      <Form layout="horizontal" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
        <FilterFormItem
          label="Assetklasse"
          tooltip={FILTER_TOOLTIPS.assetClass}
          value={selectedFilters.assetClass}
          onChange={(value) => onFilterChange(value, 'assetClass')}
          options={filterOptions?.assetClass.map((value: string) => ({
            label: String(value),
            value,
          }))}
          mode="multiple"
        />

        <FilterFormItem
          label="Klimapfad"
          tooltip={FILTER_TOOLTIPS.pathway}
          value={selectedFilters.pathway}
          onChange={(value) => onFilterChange(value, 'pathway')}
          options={filterOptions?.pathway.map((value: number) => ({ label: String(value), value }))}
          mode="multiple"
          formatLabel={(value) => `${value}°C`}
        />

        <FilterFormItem
          label="Bezugsfläche"
          tooltip={FILTER_TOOLTIPS.referenceArea}
          value={selectedFilters.referenceArea}
          onChange={(value) => onFilterChange(value, 'referenceArea')}
          options={filterOptions?.referenceArea.map((value: string) => ({
            label: String(value),
            value,
          }))}
          mode="multiple"
        />

        <FilterFormItem
          label="Land"
          tooltip={FILTER_TOOLTIPS.country}
          value={selectedFilters.country}
          onChange={(value) => onFilterChange(value, 'country')}
          options={filterOptions?.country?.map((value: string) => ({
            label: String(value),
            value,
          }))}
          mode="multiple"
        />

        <FilterFormItem
          label="Zieljahr"
          tooltip={FILTER_TOOLTIPS.yearEnd}
          value={selectedFilters.yearEnd}
          onChange={(value) => onFilterChange(value, 'yearEnd')}
          options={filterOptions?.yearEnd.map((value: number) => ({ label: String(value), value }))}
          mode="multiple"
          placeholder="Zieljahr auswählen (Standard: 2045)"
        />

        {/* <FilterFormItem
          label="Version"
          tooltip={FILTER_TOOLTIPS.version}
          value={selectedFilters.version}
          onChange={(value) => onFilterChange(value, 'version')}
          options={filterOptions?.version.map((value: number) => ({ label: String(value), value }))}
          defaultValue={203}
          isLast
          placeholder="Version auswählen (Standard: 203)"
        /> */}
      </Form>
    </Card>
  );
};

export default AssetPathwayFilters;
