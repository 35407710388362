import React, { useEffect, useState } from 'react';
import {
  //  Alert,
  Form,
  Select,
  // Typography,
  message,
} from 'antd';
import axios from 'axios';
import { apiUrl } from '../../common/url';
import * as Sentry from '@sentry/react';
// import DataCollUpload from './DataUploadDashboard';
// import { formatDate } from '../../common/datetime';

const mockDataCollTableNames: DataCollTableNames = {
  data_coll: [
    'c902_data_coll',
    'c902_data_coll_2019',
    'c902_data_coll_2020',
    'c902_data_coll_2021',
    'c902_data_coll_2022',
  ],
  data_coll_upload: ['c902_data_coll_upload', 'c902_data_coll_2022_upload'],
  data_coll_diff: ['c902_data_coll_diff', 'c902_data_coll_2022_diff'],
  data_coll_backup: ['c902_data_coll_backup', 'c902_data_coll_2022_backup'],
};

interface DataCollTableNames {
  data_coll: string[];
  data_coll_upload: string[];
  data_coll_diff: string[];
  data_coll_backup: string[];
}

interface DataCollSelectionProps {
  client: { id: string; name?: string; token: string; permissions: string[] };
  dataCollName?: string;
  setDataCollName: React.Dispatch<React.SetStateAction<string | undefined>>;
  hasUploadTable?: boolean;
  setHasUploadTable: React.Dispatch<React.SetStateAction<boolean>>;
  hasDiffTable?: boolean;
  setHasDiffTable: React.Dispatch<React.SetStateAction<boolean>>;
}

const DataCollSelection: React.FC<DataCollSelectionProps> = ({
  dataCollName,
  setDataCollName,
  hasUploadTable,
  setHasUploadTable,
  hasDiffTable,
  setHasDiffTable,
  client,
}) => {
  const [dataCollNames, setDataCollNames] = useState<{ label: string; value: string }[]>([]);
  // const [customDataCollName, setCustomDataCollName] = useState<string | undefined>(undefined);
  const [dataCollUploadTables, setDataCollUploadTables] = useState<string[]>([]);

  const getDataCollNames = () => {
    axios
      .get(`${apiUrl}/b2zero/${client.id}/get_data_coll_table_names`, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        const { data_coll, data_coll_upload } = response.data as DataCollTableNames;
        setDataCollNames(
          data_coll.map((data_coll_name: string) => ({
            label: data_coll_name,
            value: data_coll_name,
          }))
        );
        if (data_coll.length > 0) {
          setDataCollName(data_coll[data_coll.length - 1]);
        }
        setDataCollUploadTables(data_coll_upload);
      })
      .catch((error) => {
        message.error(
          'Fehler beim Abrufen der vorhandenen data_coll Tabellen: ' + error.message,
          3
        );
        message.warning('Using mock response for client 902');
        const { data_coll, data_coll_upload } = mockDataCollTableNames;

        setDataCollNames(
          data_coll.map((data_coll_name: string) => ({
            label: data_coll_name,
            value: data_coll_name,
          }))
        );
        if (data_coll.length > 0) {
          setDataCollName(data_coll[data_coll.length - 1]);
        }
        setDataCollUploadTables(data_coll_upload);

        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra(
            'hint',
            'Fehler beim Abrufen der vorhandenen data_coll Tabellen: ' + error.message
          );
          Sentry.captureException(error);
        });
        return [];
      });
  };

  useEffect(() => {
    getDataCollNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDataCollName = (value: string) => {
    setDataCollName(value);
    setHasUploadTable(dataCollUploadTables.includes(`${value}_upload`));
    console.log(dataCollUploadTables.includes(`${value}_upload`));
    console.log(dataCollUploadTables);
    console.log(`${value}_upload`);
  };

  // const validateCustomDataCollName = (_: any, value: string) => {
  //   if(value === undefined || value === "") {
  //     return Promise.resolve();
  //   }
  //   if (!value.startsWith(`c${client.id}_data_coll`)) {
  //     return Promise.reject(new Error(`Der Name muss mit "c${client.id}_data_coll" beginnen.`));
  //   }
  //   if (dataCollNames.some(name => name.value === value)) {
  //     return Promise.reject(new Error('Der Name darf nicht bereits existieren.'));
  //   }
  //   return Promise.resolve();
  // };

  return (
    <Form>
      <Form.Item label="Wählen Sie die zu ersetzende data_coll Tabelle">
        <Select
          placeholder="Bitte wählen Sie die zu ersetzende data_coll Tabelle."
          style={{ width: 200 }}
          onChange={handleChangeDataCollName}
          value={dataCollName}
          options={dataCollNames}
          // disabled={customDataCollName !== undefined && customDataCollName !== ""}
        />
      </Form.Item>
      {/* <Form.Item label="Oder bennen Sie die neu zu erstellende data_coll Tabelle"
    name="customDataCollName"
    rules={[{ validator: validateCustomDataCollName }]}
    >
       <Input
        placeholder={`${client.id}_data_coll_YYYY`}
        style={{ width: 200, marginTop: 8 }}
        onChange={(e) => setCustomDataCollName(e.target.value)}
        value={customDataCollName}
        allowClear
      />
      </Form.Item> */}
    </Form>
  );
};

export default DataCollSelection;
