import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FC, useEffect, useRef, useState } from 'react';
import { Alert, Layout, message, Spin } from 'antd';
import './App.css';
import Navbar from './components/navbar/Navbar';
import Report from './components/powerBi/Report';
import ActionPlanningTopDown from './components/actionPlanning/ActionPlanningTopDown';
import ActionPlanningBottomUp from './components/actionPlanning/ActionPlanningBottomUp';
import Recalculation from './components/calc/Recalculation';
import Administration from './components/administration/Administration';
import NotFound from './components/notFound/NotFound';

import { b2cPolicies, loginRequest } from './authConfig';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { EventType, InteractionRequiredAuthError, AccountInfo } from '@azure/msal-browser';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { apiUrl } from './common/url';
import Welcome from './components/Welcome';
import UsageInfoDrawer from './components/usageInfoDrawer/UsageInfoDrawer';
import ClientAdministration from './components/administration/ClientAdministration';
import InputParameterSelection from './components/modelingParameters/InputParameterSelection';
import PortfolioData from './components/portfolioData/PortfolioData';
import SentryRouteTracker from './components/SentryRouteTracker';
import TimeSeriesSelection from './components/modelingParameters/timeSeriesSelection/TimeSeriesSelection';
import { renewAdminToken, renewClientToken, logout, renewTokensIfNeeded } from './common/auth';
import DataUploadDashboard from './components/dataCollUpload/DataUploadDashboard';
import CrremGuidelinesDashboard from './components/guidelines/crrem/CrremGuidelinesDashboard';

const { Header, Content } = Layout;

interface ClientWithReportType {
  id: string;
  reportType: string;
  name: string;
}
interface ClientWithPermissions {
  id: string;
  name: string;
  token: string;
  permissions: string[];
}

const App: FC = () => {
  const location = window.location.pathname;
  const { inProgress, instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [clients, setClients] = useState<ClientWithReportType[]>([]);
  const [adminRoles, setAdminRoles] = useState<string[]>([]);
  const [adminToken, setAdminToken] = useState<string>('');
  const [client, setClient] = useState<ClientWithPermissions | undefined>();
  const [authCompleted, setAuthCompleted] = useState<boolean>(false);
  const lastActivityTime = useRef(Date.now());
  const [showAlert, setShowAlert] = useState(false);
  const [remainingTimeMessage, setRemainingTimeMessage] = useState('');
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const updateLastActivityTime = () => {
    lastActivityTime.current = Date.now();
  };

  const checkForInactivity = () => {
    const currentTime = Date.now();
    const timeElapsed = currentTime - lastActivityTime.current;
    const timeUntilLogout = 14400000; // 4 hours in milliseconds
    const warningTime = 13800000; // 3 hours and 45 minutes in milliseconds

    if (timeElapsed < warningTime) {
      setShowAlert(false);
      return;
    }

    if (timeElapsed >= warningTime && timeElapsed < timeUntilLogout) {
      const remainingTime = timeUntilLogout - timeElapsed;
      const minutesRemaining = Math.floor(remainingTime / 60000);
      const secondsRemaining = ((remainingTime % 60000) / 1000).toFixed(0);

      setRemainingTimeMessage(
        `Sie werden in ${minutesRemaining} Minuten und ${secondsRemaining} Sekunden ausgeloggt.`
      );
      setShowAlert(true);
    } else if (timeElapsed >= timeUntilLogout && !isLoggingOut) {
      setIsLoggingOut(true);
      logout(instance, inProgress, setIsLoggingOut);
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const isLocal = apiUrl.includes('localhost');
      console.log(`Using ${isLocal ? 'LOCAL' : 'TEST'} environment (${apiUrl})`);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      handleLoginSuccess();
    }
    setAuthCompleted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const user: AccountInfo = accounts[0];
      Sentry.setUser({ email: user.username, client_id: client?.id, client_name: client?.name });
    }
  }, [accounts, client]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        // @ts-ignore
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        // @ts-ignore
        if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance.getAllAccounts().find(
            (account) =>
              // @ts-ignore
              account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
              // @ts-ignore
              account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
              // @ts-ignore
              (account.idTokenClaims && account.idTokenClaims['tfp']) ===
                b2cPolicies.names.signUpSignIn
          );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }
      }

      // @ts-ignore
      if (event.eventType === EventType.SSO_SILENT_SUCCESS && event.payload.account) {
        // @ts-ignore
        console.log('ssoSilent success');
      }
    });

    // Loading client based on url
    if (location.startsWith('/client')) {
      const clientId = location.split('/')[2];
      changeClient(clientId);
    }

    setAuthCompleted(true);

    const inactivityTimer = setInterval(checkForInactivity, 1000);
    const tokenRenewalTimer = setInterval(
      () =>
        renewTokensIfNeeded(
          instance,
          adminToken,
          client,
          setAdminRoles,
          setAdminToken,
          setAuthCompleted,
          setClient,
          clients
        ),
      60000
    );

    document.addEventListener('mousemove', updateLastActivityTime);
    document.addEventListener('keypress', updateLastActivityTime);

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }

      clearInterval(inactivityTimer);
      clearInterval(tokenRenewalTimer);
      document.removeEventListener('mousemove', updateLastActivityTime);
      document.removeEventListener('keypress', updateLastActivityTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginSuccess = () => {
    const account = instance.getActiveAccount();

    if (account) {
      const silentRequest = {
        ...loginRequest,
        account: account,
      };

      instance
        .acquireTokenSilent(silentRequest)
        .then((response) => {
          axios
            .get(apiUrl + '/auth/get_contexts', {
              headers: { Authorization: `Bearer ${response.accessToken}` },
            })
            .then((responseGetContexts) => {
              const clientsNew = responseGetContexts.data.clients.map((client: any) => ({
                id: client.id,
                reportType: client.report_type,
                name: client.name,
              }));
              setClients(clientsNew);
              // Loading client based on url
              if (location.startsWith('/client')) {
                const clientId = location.split('/')[2];
                if (clientsNew.some((c: any) => c.id === clientId)) {
                  changeClient(clientId);
                } else {
                  message.error(
                    'Kein Zugriff auf den in der URL definierten Kunden: ' + clientId,
                    3
                  );
                  window.location.href = '/';
                }
              }

              if (responseGetContexts.data.admin) {
                renewAdminToken(
                  response.accessToken,
                  responseGetContexts.data.admin,
                  setAdminRoles,
                  setAdminToken,
                  setAuthCompleted
                );
              }
            })
            .catch((error) => {
              console.error('Fehler beim Laden der Kundeninformationen:', error);
              message.error('Fehler beim Laden der Kundeninformationen: ' + error.message, 6);
              Sentry.withScope((scope) => {
                scope.setLevel('warning');
                scope.setExtra(
                  'hint',
                  'Fehler beim Laden der Kundeninformationen: ' + error.message
                );
                Sentry.captureException(error);
              });
            })
            .finally(() => {
              setAuthCompleted(true);
            });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(silentRequest);
          } else {
            console.error('Ein Authentifizierungs-Fehler ist aufgetreten:', error);
            message.error(
              'Ein Authentifizierungs-Fehler ist aufgetreten. Bitte die Seite erneut laden.'
            );
            Sentry.withScope((scope) => {
              scope.setLevel('warning');
              scope.setExtra('hint', 'Ein Authentifizierungs-Fehler ist aufgetreten');
              Sentry.captureException(error);
            });
          }
        })
        .finally(() => {
          setAuthCompleted(true);
        });
    }
  };

  const changeClient = (clientId?: string) => {
    setAuthCompleted(false);
    if (!clientId) {
      setClient(undefined);
      return;
    }
    const account = instance.getActiveAccount();

    if (account) {
      const silentRequest = {
        ...loginRequest,
        account: account,
      };
      instance
        .acquireTokenSilent(silentRequest)
        .then((response) => {
          renewClientToken(clientId, response.accessToken, setClient, setAuthCompleted, clients);
        })
        .catch((error) => {
          console.log('401 from gateway');
          console.log(error);
        })
        .finally(() => {
          setAuthCompleted(true);
        });
    }
  };

  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <BrowserRouter>
        <SentryRouteTracker />
        <Header style={{ padding: '0px 0px' }}>
          <Navbar
            clients={clients}
            adminRoles={adminRoles}
            client={client}
            changeClient={changeClient}
          />
        </Header>
        <Content style={{ padding: '12px 12px' }}>
          {showAlert && (
            <Alert message="Noch da?" description={remainingTimeMessage} type="warning" showIcon />
          )}
          <Routes>
            {!isAuthenticated && inProgress === 'none' && <Route path="*" element={<Welcome />} />}
            {(client?.permissions.includes('recalculate') ||
              client?.permissions.includes('recalculate_objects')) && (
              <Route
                path="/client/:clientId/calc"
                element={
                  <>
                    <Recalculation client={client} />
                    <UsageInfoDrawer site="Neuberechnung" />
                  </>
                }
              />
            )}
            {client?.permissions.includes('get_aux_general_parameters') && (
              <Route
                path="/client/:clientId/data-editing/modeling-parameters/input-parameter-selection"
                element={
                  <>
                    <InputParameterSelection client={client} />
                    <UsageInfoDrawer site="Auswahl Eingabeparameter" />
                  </>
                }
              />
            )}
            {(adminRoles.includes('data_admin') ||
              client?.permissions.includes('get_clients_selected_asm')) &&
              client &&
              clients && (
                <Route
                  path="/client/:clientId/data-editing/modeling-parameters/time-series-selection"
                  element={
                    <>
                      <TimeSeriesSelection
                        client={client}
                        clients={clients}
                        adminRoles={adminRoles}
                        adminToken={adminToken}
                      />
                      <UsageInfoDrawer site="Auswahl Zeitreihen" />
                    </>
                  }
                />
              )}
            {client?.permissions.includes('get_data_coll') && (
              <Route
                path="/client/:clientId/data-editing/portfolio-data"
                element={
                  <>
                    <PortfolioData client={client} />
                    <UsageInfoDrawer site="Portfoliodaten" />
                  </>
                }
              />
            )}
            {client?.permissions.includes('get_data_coll_blocked_status') && (
              <Route
                path="/client/:clientId/data-editing/data-upload"
                element={
                  <>
                    <DataUploadDashboard
                      client={client}
                      adminRoles={adminRoles}
                      adminToken={adminToken}
                    />
                    <UsageInfoDrawer site="Daten Upload" />
                  </>
                }
              />
            )}
            {client?.permissions.includes('get_top_down_measures') && (
              <Route
                path="/client/:clientId/input/top-down"
                element={
                  <>
                    <ActionPlanningTopDown client={client} />
                    <UsageInfoDrawer site="Maßnahmenplanung Top-Down" />
                  </>
                }
              />
            )}
            {client?.permissions.includes('get_bottom_up_data_coll') && (
              <Route
                path="/client/:clientId/input/bottom-up"
                element={
                  <>
                    <ActionPlanningBottomUp client={client} />
                    <UsageInfoDrawer site="Maßnahmenplanung Bottom-Up" />
                  </>
                }
              />
            )}
            {client?.permissions.includes('get_ghg_asset_pathways') && (
              <Route
                path="/client/:clientId/guidelines/crrem"
                element={
                  <>
                    <CrremGuidelinesDashboard client={client} />
                    <UsageInfoDrawer site="CRREM" />
                  </>
                }
              />
            )}
            {client?.permissions.includes('get_pbi_embed_info_for_report') && (
              <Route
                path="/client/:clientId/report/*"
                element={
                  <>
                    <Report client={client} />
                    <UsageInfoDrawer site="b2zero Dashboard" />
                  </>
                }
              />
            )}
            {adminRoles.includes('benutzerverwaltung') && (
              <Route
                path="/administration/users"
                element={
                  <>
                    <Administration adminRoles={adminRoles} adminToken={adminToken} />
                    <UsageInfoDrawer site="Benutzerverwaltung" />
                  </>
                }
              />
            )}
            {adminRoles.includes('kundenverwaltung') && (
              <Route
                path="/administration/clients"
                element={
                  <>
                    <ClientAdministration adminRoles={adminRoles} adminToken={adminToken} />
                    <UsageInfoDrawer site="Kundenverwaltung" />
                  </>
                }
              />
            )}
            {isAuthenticated && <Route path="/" element={<Welcome />} />}
            {isAuthenticated && <Route path="/welcome" element={<Welcome clients={clients} />} />}
            {(!authCompleted || inProgress !== 'none' || (!adminToken && !client?.token)) && (
              <Route
                path="*"
                element={
                  <Spin
                    className="auth-in-progress-spin"
                    style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
                  />
                }
              />
            )}
            {authCompleted && inProgress === 'none' && client?.permissions && (
              <Route path="*" element={<NotFound />} />
            )}
          </Routes>
        </Content>
      </BrowserRouter>
    </Layout>
  );
};

export default App;
