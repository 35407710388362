import React, { useEffect, useState } from 'react';
import {
  List,
  Typography,
  Form,
  Checkbox,
  Button,
  Tooltip,
  message,
  Radio,
  Modal,
  Spin,
} from 'antd';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { apiUrl } from '../../common/url';

interface ClientType {
  client_id: number;
  name: string;
}

interface BulkUserAdministrationProps {
  selectedUsers: { label: string; value: string }[];
  handleChangeSelectedUsers: (selectedUsers: { label: string; value: string }[]) => void;
  users: { email: string; azureObjectId: string }[];
  setUsers: (users: { email: string; azureObjectId: string }[]) => void;
  adminRoles: string[];
  adminToken: string;
}

const saveModeOptions = [
  {
    label: 'Markierte Felder Speichern',
    value: 'save checked',
    tooltip: 'Speichert nur markierte Felder, es werden also ausschließlich Felder hinzugefügt',
  },
  {
    label: 'Alle Speichern',
    value: 'save all',
    tooltip: 'Speichert alle (markierte und nicht markierte) Felder',
  },
  {
    label: 'Nicht Markierte Felder Speichern',
    value: 'save unchecked',
    tooltip: 'Speichert nur nicht markierte Felder, es werden also ausschließlich Felder entfernt ',
  },
];

const BulkUserAdministration: React.FC<BulkUserAdministrationProps> = ({
  selectedUsers,
  handleChangeSelectedUsers,
  adminRoles,
  adminToken,
  users,
  setUsers,
}) => {
  const [availableClientRoles, setAvailableClientRoles] = useState<string[]>([]);
  const [availableAdminRoles, setAvailableAdminRoles] = useState<string[]>([]);
  const [clients, setClients] = useState<ClientType[]>([]);
  const [saveMode, setSaveMode] = useState<string>(saveModeOptions[0].value);
  const [saveModeUserDetails, setSaveModeUserDetails] = useState<string>(saveModeOptions[0].value);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = (
    url: string,
    setState: React.Dispatch<React.SetStateAction<any>>,
    errorMessage: string
  ) => {
    setIsLoading(true);
    axios
      .get(url, { headers: { Authorization: `Bearer ${adminToken}` } })
      .then((response) => {
        setState(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(errorMessage, 3);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', errorMessage);
          Sentry.captureException(error);
        });
      });
  };

  useEffect(() => {
    fetchData(
      `${apiUrl}/admin/manage_clients/get_all_clients`,
      setClients,
      'Fehler beim Laden der Kunden'
    );
    fetchData(
      `${apiUrl}/admin/manage_clients/get_all_admin_roles`,
      setAvailableAdminRoles,
      'Fehler beim Laden der Admin-Rollen'
    );
    fetchData(
      `${apiUrl}/admin/manage_clients/get_all_user_roles`,
      setAvailableClientRoles,
      'Fehler beim Laden der Client-Rollen'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminToken]);

  const updateUserClientRoles = (roles: any, clientId: number) => {
    const rolesWithoutClientId = Object.keys(roles).reduce((acc, key) => {
      if (key.startsWith(clientId.toString())) {
        return {
          ...acc,
          [key.replace(`${clientId}-`, '')]: roles[key] !== undefined ? roles[key] : false,
        };
      }
      return acc;
    }, {});

    axios
      .post(
        `${apiUrl}/admin/manage_users/bulk_update_user_client_memberships`,
        {
          save_mode: saveMode,
          user_ids: selectedUsers.map((user) => user.value),
          client_id: clientId,
          roles: rolesWithoutClientId,
        },
        { headers: { Authorization: `Bearer ${adminToken}` } }
      )
      .then((response) => {
        console.log(response.data);
        message.success('Benutzer-Client-Memberships aktualisiert', 3);
      })
      .catch((error) => {
        console.log(error.response.data);
        message.error('Fehler beim Aktualisieren der Benutzer-Client-Memberships', 6);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Aktualisieren der Benutzer-Client-Memberships');
          Sentry.captureException(error);
        });
      });
  };

  const addUserClientRolesForAllClients = (values: any) => {
    // only the values that are selected set to true, remove the prefix 'add-all-' from key
    const selectedRolesArray: string[] = Object.keys(values)
      .filter((key) => values[key] === true)
      .map((key) => key.replace('add-all-', ''));
    axios
      .post(
        `${apiUrl}/admin/manage_users/add_user_client_memberships_for_all_clients`,
        { user_ids: selectedUsers.map((user) => user.value), roles_to_add: selectedRolesArray },
        { headers: { Authorization: `Bearer ${adminToken}` } }
      )
      .then((response) => {
        if (response.status === 207) {
          console.log('Partial success:', response.data);
          message.warning('Einige Benutzer-Client-Memberships konnten nicht ergänzt werden', 7);
          // Handle partial success here, e.g., display detailed error messages
          response.data.error.forEach((error: any) => {
            message.error(`Fehler für Benutzer ${error.user_id}: ${error.message}`, 7);
          });
          Sentry.withScope((scope) => {
            scope.setLevel('warning');
            scope.setExtra(
              'hint',
              'Benutzer-Client-Memberships konnten nicht ergänzt werden: ' + response.data
            );
            Sentry.captureMessage(response.data);
          });
        } else {
          console.log(response.data);
          message.success('Benutzer-Client-Memberships für alle Clients ergänzt', 3);
          // in case of displaying actual values for multiple users, update here
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        message.error('Fehler beim Ergänzen der Benutzer-Client-Memberships', 3);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Ergänzen der Benutzer-Client-Memberships');
          Sentry.captureException(error);
        });
      });
  };

  const revokeUserClientRolesForAllClients = (values: any) => {
    const selectedRolesArray: string[] = Object.keys(values)
      .filter((key) => values[key] === true)
      .map((key) => key.replace('revoke-all-', ''));
    axios
      .post(
        `${apiUrl}/admin/manage_users/revoke_user_client_memberships_for_all_clients`,
        { user_ids: selectedUsers.map((user) => user.value), roles_to_revoke: selectedRolesArray },
        { headers: { Authorization: `Bearer ${adminToken}` } }
      )
      .then((response) => {
        if (response.status === 207) {
          console.log('Partial success:', response.data);
          message.warning('Einige Benutzer-Client-Memberships konnten nicht entfernt werden', 7);
          // Handle partial success here, e.g., display detailed error messages
          response.data.error.forEach((error: any) => {
            message.error(`Fehler für Benutzer ${error.user_id}: ${error.message}`, 7);
          });
          Sentry.withScope((scope) => {
            scope.setLevel('warning');
            scope.setExtra(
              'hint',
              'Benutzer-Client-Memberships konnten nicht entfernt werden: ' + response.data
            );
            Sentry.captureMessage(response.data);
          });
        } else {
          console.log(response.data);
          message.success('Benutzer-Client-Memberships für alle Clients entfernt', 3);
          // in case of displaying actual values for multiple users, update here
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        message.error('Fehler beim Entfernen der Benutzer-Client-Memberships', 3);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Entfernen der Benutzer-Client-Memberships');
          Sentry.captureException(error);
        });
      });
  };

  const updateUsers = (values: any) => {
    console.log(values);

    // Ensure all roles are included in the payload, even if set to false
    const completeValues = availableAdminRoles.reduce(
      (acc, role) => {
        acc[role] = values[role] || false;
        return acc;
      },
      { ...values }
    );

    // Prepare the payload with the provided values, user_ids, and save_mode
    const payload = {
      ...completeValues,
      user_ids: selectedUsers.map((user) => user.value),
      save_mode: saveModeUserDetails,
    };

    axios
      .post(`${apiUrl}/admin/manage_users/update_users`, payload, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((response) => {
        if (response.status === 207) {
          message.warning('Einige Benutzer konnten nicht aktualisiert werden:', 6);
          console.log('Partial success:', response.data);
          Sentry.withScope((scope) => {
            scope.setLevel('warning');
            scope.setExtra(
              'hint',
              'Einige Benutzer konnten nicht aktualisiert werden: ' + response.data
            );
            Sentry.captureMessage(response.data);
          });
        } else {
          console.log(response.data);
          message.success('Benutzer aktualisiert', 3);
        }
      })
      .catch((error) => {
        console.log(error.message);
        message.error('Fehler beim Aktualisieren der Benutzer: ' + error.message, 3);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Aktualisieren der Benutzer: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Ausgewählte Benutzer löschen',
      content: `Sind Sie sicher, dass Sie die ausgewählten Benutzer löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden. Ausgewählte Benutzer: ${selectedUsers.map((user) => user.label).join(', ')}`,
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nein',
      onOk() {
        handleDeleteUsers();
      },
      onCancel() {
        console.log('Cancel delete');
      },
    });
  };

  const handleDeleteUsers = () => {
    const userIdsToDelete = selectedUsers.map((user) => user.value);
    axios
      .post(
        `${apiUrl}/admin/manage_users/delete_users`,
        { user_ids: userIdsToDelete },
        { headers: { Authorization: `Bearer ${adminToken}` } }
      )
      .then((response) => {
        if (response.status === 207) {
          message.warning('Einige Benutzer konnten nicht gelöscht werden.', 6);
          console.log('Partial success:', response.data);
          Sentry.withScope((scope) => {
            scope.setLevel('warning');
            scope.setExtra(
              'hint',
              'Einige Benutzer konnten nicht gelöscht werden: ' + response.data
            );
            Sentry.captureMessage(response.data);
          });
          // Handle partial success, e.g., update the UI based on response.data
        } else {
          message.success('Benutzer gelöscht', 6);
          setUsers(users.filter((user) => !userIdsToDelete.includes(user.azureObjectId)));
          handleChangeSelectedUsers([]);
        }
      })
      .catch((error) => {
        console.log(error.message);
        message.error('Fehler beim Löschen der ausgewählten Benutzer: ' + error.message, 3);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Löschen der ausgewählten Benutzer: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      <List
        header={
          <>
            <Typography.Title level={3} style={{ marginTop: 0 }}>
              Benutzer-Kunde-Rollen ({selectedUsers.map((user) => user.label).join(', ')})
            </Typography.Title>
            Speicher-Modus:
            <Radio.Group
              style={{ marginLeft: 10 }}
              onChange={(e) => setSaveMode(e.target.value)}
              value={saveMode}
              optionType="button"
              buttonStyle="solid"
            >
              {saveModeOptions.map((option) => (
                <Tooltip key={option.value} title={option.tooltip}>
                  <Radio.Button value={option.value}>{option.label}</Radio.Button>
                </Tooltip>
              ))}
            </Radio.Group>
          </>
        }
        bordered
        style={{ width: '100%' }}
        key={`user-client-memberships-selected-users`}
      >
        {clients.map((client) => (
          <List.Item key={`uc-membership-selected-users-${client.client_id}`}>
            {
              <Typography style={{ fontWeight: 'bold', width: '300px' }}>
                {client.name} ({client.client_id})
              </Typography>
            }
            <Form
              layout="inline"
              labelWrap={true}
              labelCol={{ span: 40 }}
              wrapperCol={{ offset: 40 }}
              onFinish={(values) => updateUserClientRoles(values, client.client_id)}
              key={`form-selected-users-${client.client_id}`}
            >
              {availableClientRoles?.map((role) => (
                <Form.Item
                  label={role}
                  name={`${client.client_id}-${role}`}
                  valuePropName="checked"
                  key={`role-${client.client_id}-${role}`}
                >
                  <Checkbox />
                </Form.Item>
              ))}
              <Form.Item>
                <Tooltip
                  title={saveModeOptions.find((option) => option.value === saveMode)?.tooltip}
                >
                  <Button type="primary" htmlType="submit" style={{}}>
                    Speichern
                  </Button>
                </Tooltip>
              </Form.Item>
            </Form>
          </List.Item>
        ))}
        <List.Item key={`user-details-add-for-selected-users`}>
          {
            <Typography style={{ fontWeight: 'bold', width: '300px' }}>
              Rollen für alle Clients hinzufügen
            </Typography>
          }
          <Form
            layout="inline"
            labelWrap={true}
            labelCol={{ span: 40 }}
            wrapperCol={{ offset: 40 }}
            onFinish={(values) => addUserClientRolesForAllClients(values)}
            key={`add-for-all-selected-users}`}
          >
            {availableClientRoles?.map((role) => (
              <Form.Item
                label={role}
                name={`add-all-${role}`}
                valuePropName="checked"
                key={`add-role-for-all-${role}`}
              >
                <Checkbox />
              </Form.Item>
            ))}
            <Form.Item>
              <Tooltip title="Fügt die aktiv gesetzten Rechte für alle Clients hinzu. Um diese zu entfernen müssen diese pro client entfernt werden oder für alle Kunden enternt werden.">
                <Button danger htmlType="submit">
                  Auswahl hinzufügen
                </Button>
              </Tooltip>
            </Form.Item>
          </Form>
        </List.Item>
        <List.Item key={`user-details-revoke-for-all-selected-users`}>
          {
            <Typography style={{ fontWeight: 'bold', width: '300px' }}>
              Rollen für alle Clients entfernen
            </Typography>
          }
          <Form
            layout="inline"
            labelWrap={true}
            labelCol={{ span: 40 }}
            wrapperCol={{ offset: 40 }}
            onFinish={(values) => revokeUserClientRolesForAllClients(values)}
            key={`revoke-for-all-selected-users`}
          >
            {availableClientRoles?.map((role) => (
              <Form.Item
                label={role}
                name={`revoke-all-${role}`}
                valuePropName="checked"
                key={`revoke-role-for-all${role}`}
              >
                <Checkbox />
              </Form.Item>
            ))}
            <Form.Item>
              <Tooltip title="Entfernt die inaktiv gesetzten Rechte für alle Clients hinzu. Um diese zu hinzuzufügen müssen diese pro client hinzugefüt werden oder für alle Clients entfernt werden.">
                <Button danger htmlType="submit">
                  Auswahl entfernen
                </Button>
              </Tooltip>
            </Form.Item>
          </Form>
        </List.Item>
      </List>
      <br />
      <List
        header={
          <>
            <Typography.Title level={3} style={{ marginTop: 0 }}>
              Benutzerdetails ({selectedUsers.map((user) => user.label).join(', ')})
            </Typography.Title>
            Speicher-Modus:
            <Radio.Group
              style={{ marginLeft: 10 }}
              onChange={(e) => setSaveModeUserDetails(e.target.value)}
              value={saveModeUserDetails}
              optionType="button"
              buttonStyle="solid"
            >
              {saveModeOptions.map((option) => (
                <Tooltip key={option.value} title={option.tooltip}>
                  <Radio.Button value={option.value}>{option.label}</Radio.Button>
                </Tooltip>
              ))}
            </Radio.Group>
          </>
        }
        bordered
        style={{ width: '100%' }}
        key={`user-details-selected-users`}
      >
        <List.Item key={`admin-roles-selected-users`}>
          {
            <Typography style={{ fontWeight: 'bold', width: '300px' }}>
              Benutzerdetails anpassen
            </Typography>
          }
          <Form
            layout="inline"
            labelWrap={true}
            labelCol={{ span: 40 }}
            wrapperCol={{ offset: 40 }}
            // initialValues={userWithRoles.adminRoles?.reduce((acc, role) => ({ ...acc, [role]: true }), { email: userWithRoles.email, blockSignin: userWithRoles.blockSignin })}
            onFinish={updateUsers}
          >
            {availableAdminRoles?.map((role) => (
              <Form.Item
                label={role}
                name={role}
                valuePropName="checked"
                key={`revoke-role-${role}`}
              >
                <Checkbox />
              </Form.Item>
            ))}
            <Form.Item label="Account gesperrt" name="blockSignin" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item>
              <Button danger onClick={() => showDeleteConfirm()} style={{ marginLeft: '10px' }}>
                Benutzer löschen
              </Button>
            </Form.Item>
            <Form.Item>
              <Tooltip
                title={
                  saveModeOptions.find((option) => option.value === saveModeUserDetails)?.tooltip
                }
              >
                <Button type="primary" htmlType="submit">
                  Bestätigen
                </Button>
              </Tooltip>
            </Form.Item>
          </Form>
        </List.Item>
      </List>
    </>
  );
};

export default BulkUserAdministration;
