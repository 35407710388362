import React, { useState } from 'react';
import { Alert, Button, message, Modal, Typography } from 'antd';
import DataCollSelection from './DataCollSelection';
import DataCollBlockedStatusDisplay from './DataCollBlockedStatusDisplay';
import { DataCollBlockedStatus } from './types';
import axios from 'axios';
import { apiUrl } from '../../common/url';
import * as Sentry from '@sentry/react';
import { useMsal } from '@azure/msal-react';

interface DataUploadDashboardProps {
  client: { id: string; name?: string; token: string; permissions: string[] };
  adminRoles: string[];
  adminToken: string;
}

const DataUploadDashboard: React.FC<DataUploadDashboardProps> = ({
  client,
  adminRoles,
  adminToken,
}) => {
  const [dataCollName, setDataCollName] = useState<string>();
  const [hasUploadTable, setHasUploadTable] = useState<boolean>(false);
  const [hasDiffTable, setHasDiffTable] = useState<boolean>(false);

  const [dataCollBlockedStatus, setDataCollBlockedStatus] = useState<DataCollBlockedStatus>();
  const { instance } = useMsal();

  const handleStartEditing = () => {
    Modal.confirm({
      title: `Bearbeitung ${hasUploadTable ? 'fortsetzen' : 'starten'}?`,
      content: `Möchten Sie die Bearbeitung der Tabelle ${dataCollName} ${hasUploadTable ? 'fortsetzen' : 'starten'}? Dies wird andere Benutzer daran hindern, die Tabelle zu bearbeiten.`,
      onOk: () => {
        axios
          .post(
            `${apiUrl}/b2zero/${client.id}/update_data_coll_blocked_status`,
            {
              data_coll_name: dataCollName,
              isBlocked: true,
            },
            {
              headers: { Authorization: `Bearer ${client.token}` },
            }
          )
          .then((response) => {
            message.success('Die Tabelle wurde erfolgreich zur Bearbeitung blockiert.');
            const dataCollBlockedStatusNew = response.data as DataCollBlockedStatus;
            dataCollBlockedStatusNew.blocking_user_is_current_user =
              dataCollBlockedStatusNew.blocking_user_id ===
              instance.getActiveAccount()?.localAccountId;
            setDataCollBlockedStatus(dataCollBlockedStatusNew);
          })
          .catch((error) => {
            message.error('Fehler beim Blockieren der Tabelle: ' + error.message, 3);
            Sentry.captureException(error);
          });
      },
      okText: 'Ja, Bearbeitung starten',
      cancelText: 'Abbrechen',
    });
  };

  return client === undefined ? (
    <div>Please select a client.</div>
  ) : (
    <>
      <Typography.Title level={1}>Portfoliodaten Upload für {client.name}</Typography.Title>
      <DataCollSelection
        dataCollName={dataCollName}
        hasUploadTable={hasUploadTable}
        setHasUploadTable={setHasUploadTable}
        setDataCollName={setDataCollName}
        hasDiffTable={hasDiffTable}
        setHasDiffTable={setHasDiffTable}
        client={client}
      />
      <DataCollBlockedStatusDisplay
        dataCollName={dataCollName}
        dataCollBlockedStatus={dataCollBlockedStatus}
        setDataCollBlockedStatus={setDataCollBlockedStatus}
        client={client}
        adminRoles={adminRoles}
        adminToken={adminToken}
        styles={{ marginBottom: 12 }}
      />

      {hasUploadTable ? ( // TODO: move to Structual merge component
        <Alert
          message={`Upload Tabelle vorhanden.`}
          description={
            <Typography.Paragraph>
              Für die Tabelle {dataCollName} existiert eine Upload-Tabelle.
            </Typography.Paragraph>
          }
          type="success"
          showIcon
          style={{ marginBottom: 12 }}
        />
      ) : (
        <Alert
          message={`Keine Upload Tabelle vorhanden.`}
          description={
            <>
              <Typography.Paragraph>
                Für die Tabelle {dataCollName} existiert keine eine Upload-Tabelle.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Um die data_coll Tabelle zu ersetzen, laden Sie zunächst die neue Tabelle über die
                Upload-Skripte hoch. Zuvor können Sie nach Bedarf die aktuelle {dataCollName}{' '}
                mittels PgAdmin-CSV-Export sichern, diese bearbeiten und anschließend als{' '}
                {dataCollName}_upload bereitstellen.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Anschließend können Sie die Funktionalitäten zum Ersetzen der Tabelle {dataCollName}{' '}
                verwenden.
              </Typography.Paragraph>
            </>
          }
          type="warning"
          showIcon
          style={{ marginBottom: 12 }}
        />
      )}

      <Typography.Title level={3}>Struktureller Abgleich</Typography.Title>
      <Button type="primary" style={{ marginBottom: 12 }}>
        Strukturellen Abgleich starten
      </Button>
      {hasDiffTable ? (
        // TODO move to data merge component ;
        // TODO: update contents
        <Alert
          message={`Diff Tabelle vorhanden.`}
          description={
            <Typography.Paragraph>
              Für die Tabelle {dataCollName} existiert eine Diff-Tabelle. Möchten Sie die
              Bearbeitung fortsetzen?
            </Typography.Paragraph>
          }
          type="success"
          showIcon
          style={{ marginBottom: 12 }}
        />
      ) : (
        <Alert
          message={`Keine Diff Tabelle vorhanden.`}
          description={
            <>
              <Typography.Paragraph>
                Für die Tabelle {dataCollName} existiert keine eine Diff-Tabelle.
              </Typography.Paragraph>
              <Typography.Paragraph>TODO</Typography.Paragraph>
              <Typography.Paragraph>
                Anschließend können Sie die Funktionalitäten zum Anwenden der Tabelle {dataCollName}
                _diff verwenden.
              </Typography.Paragraph>
            </>
          }
          type="info"
          showIcon
          style={{ marginBottom: 12 }}
        />
      )}
      {/* TODO: add further actions, when editing is begins. */}
      {/* Could also be made less strict, so when its actively unblocked multiple users can edit. */}
      {!dataCollBlockedStatus?.isBlocked && (
        <Button type="primary" onClick={handleStartEditing}>
          {hasUploadTable ? 'Bearbeitung fortsetzen' : 'Bearbeitung starten'}
        </Button>
      )}
    </>
  );
};

export default DataUploadDashboard;
