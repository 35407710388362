import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuProps, Select } from 'antd';
import UserMenu from './UserMenu';
import { useMsal } from '@azure/msal-react';

const menuItemsDefault: { key: string; label: string; children?: any[] }[] = [
  {
    key: '/report', // the key '/report' represents the url fragment '/'. All other keys match their url fragment
    label: 'b2zero Dashboard',
  },
];

interface NavbarProps {
  clients: { id: string; name: string; reportType: string }[];
  adminRoles?: string[];
  client?: { id: string; name?: string; permissions: string[] };
  changeClient: (clientId?: string) => void;
}

const Navbar: FC<NavbarProps> = ({ clients, adminRoles, client, changeClient }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  const [menuItems, setMenuItems] = useState(menuItemsDefault);
  const [currentMenuItem, setCurrentMenuItem] = useState(location.pathname);
  const [clientsOptions, setClientsOptions] = React.useState<
    { label: string; value: string; options?: { label: string; value: string }[] }[]
  >([]);

  // Create the options for the select elements
  useEffect(() => {
    const optionsB2zeroStrategy = clients
      .filter((client) => client.reportType === 'b2zero strategy')
      .map((client) => ({ label: `${client.name} (${client.id})`, value: client.id }));
    const optionsComparison = clients
      .filter((client) => client.reportType === 'comparison')
      .map((client) => ({ label: `${client.name} (${client.id})`, value: client.id }));
    const optionsDemo = clients
      .filter((client) => client.reportType === 'demo')
      .map((client) => ({ label: `${client.name} (${client.id})`, value: client.id }));
    const optionsTest = clients
      .filter((client) => client.reportType === 'test')
      .map((client) => ({ label: `${client.name} (${client.id})`, value: client.id }));

    const allClientsOptions = [];
    if (optionsB2zeroStrategy.length > 0) {
      allClientsOptions.push({
        label: 'b2zero strategy',
        value: 'b2zero strategy',
        options: optionsB2zeroStrategy,
      });
    }
    if (optionsComparison.length > 0) {
      allClientsOptions.push({
        label: 'comparison',
        value: 'comparison',
        options: optionsComparison,
      });
    }
    if (optionsDemo.length > 0) {
      allClientsOptions.push({
        label: 'demo',
        value: 'demo',
        options: optionsDemo,
      });
    }
    if (optionsTest.length > 0) {
      allClientsOptions.push({
        label: 'test',
        value: 'test',
        options: optionsTest,
      });
    }

    setClientsOptions(allClientsOptions);
    updateMenuItems();

    // If no client is selected, select the first Kunde client or the other client if none available
    if (!client && clients.length > 0 && !location.pathname.startsWith('/client')) {
      // display Muster GmbH (id: 1) per default for empact users
      if (instance.getActiveAccount()?.username.includes('@empact.energy')) {
        if (clients.some((client) => client.id === '1')) {
          handleChangeClient('1');
        }
      } else {
        // else use the first report with type Kunde, else fallback to first available report, typically 001
        const firstKundeClientId =
          clients.find(
            (client) =>
              client.reportType === 'b2zero strategy' || client.reportType === 'comparison'
          )?.id || clients[0].id;
        handleChangeClient(firstKundeClientId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, client?.permissions, adminRoles]);

  useEffect(() => {
    if (location.pathname.startsWith('/administration/users')) {
      setCurrentMenuItem('/administration/users');
    } else if (location.pathname.startsWith('/administration/clients')) {
      setCurrentMenuItem('/administration/clients');
    } else if (location.pathname.startsWith('/client')) {
      // get content from pathname after /client/{id}/
      const clientPath = '/' + location.pathname.split('/').slice(3).join('/');
      const menuItem = menuItems.find((item) => clientPath.startsWith(item.key)); // ?.key
      let menuItemKey = menuItem?.key || '';
      if (menuItemKey.startsWith('/input') && menuItem?.children) {
        // input has children (top-down, bottom-up)
        menuItemKey = clientPath.startsWith('/input/bottom-up')
          ? '/input/bottom-up'
          : '/input/top-down';
      }

      if (menuItemKey.startsWith('/data-editing') && menuItem?.children) {
        // data-editing has children (modeling-parameters, portfolio-data)
        if (clientPath.startsWith('/data-editing/modeling-parameters')) {
          if (
            clientPath.startsWith('/data-editing/modeling-parameters/input-parameter-selection')
          ) {
            menuItemKey = '/data-editing/modeling-parameters/input-parameter-selection';
          } else if (
            clientPath.startsWith('/data-editing/modeling-parameters/time-series-selection')
          ) {
            menuItemKey = '/data-editing/modeling-parameters/time-series-selection';
          } else {
            // Default to input-parameter-selection if no specific child is matched
            menuItemKey = '/data-editing/modeling-parameters/input-parameter-selection';
          }
        } else if (clientPath.startsWith('/data-editing/data-upload')) {
          menuItemKey = '/data-editing/data-upload';
        } else if (clientPath.startsWith('/data-editing/portfolio-data')) {
          menuItemKey = '/data-editing/portfolio-data';
        } else {
          // Default to portfolio-data if no specific child is matched
          menuItemKey = '/data-editing/portfolio-data';
        }
      }
      if (clientPath.startsWith('/guidelines')) {
        menuItemKey = '/guidelines/crrem';
      }

      setCurrentMenuItem(menuItemKey);
    } else {
      setCurrentMenuItem('');
    }
  }, [location.pathname, menuItems]);

  const updateMenuItems = () => {
    const newMenuItems = [...menuItemsDefault];
    if (
      client?.permissions.includes('recalculate') ||
      client?.permissions.includes('recalculate_objects')
    ) {
      newMenuItems.push({ key: '/calc', label: 'Neuberechnung' });
    }
    if (client?.permissions.includes('get_bottom_up_data_coll')) {
      if (client?.permissions.includes('get_top_down_measures')) {
        newMenuItems.push({
          key: '/input',
          label: 'Maßnahmenplanung',
          children: [
            {
              key: '/input/bottom-up',
              label: 'Bottom-Up',
            },
            {
              key: '/input/top-down',
              label: 'Top-Down',
            },
          ],
        });
      } else {
        newMenuItems.push({ key: '/input/bottom-up', label: 'Maßnahmenplanung' });
      }
    } else if (client?.permissions.includes('get_top_down_measures')) {
      newMenuItems.push({ key: '/input/top-down', label: 'Maßnahmenplanung' });
    }

    // DatenBearbeitung {Modellierungsparameter: {Auswahl Eingabeparameter, Auswahl Zeitreihen}, Portfoliodaten}
    // If a parent menu item has only one child, the child is displayed directly
    const modelingParametersChildren = [];
    if (client?.permissions.includes('get_data_coll')) {
      modelingParametersChildren.push({
        key: '/data-editing/modeling-parameters/input-parameter-selection',
        label: 'Auswahl Eingabeparameter',
      });
    }

    if (
      adminRoles?.includes('data_admin') ||
      client?.permissions.includes('get_clients_selected_asm')
    ) {
      modelingParametersChildren.push({
        key: '/data-editing/modeling-parameters/time-series-selection',
        label: 'Auswahl Zeitreihen',
      });
    }

    const dataEditingChildren = [];

    if (client?.permissions.includes('get_data_coll_blocked_status')) {
      dataEditingChildren.push({
        key: '/data-editing/data-upload',
        label: 'Daten Upload',
      });
    }

    if (client?.permissions.includes('get_aux_general_parameters')) {
      dataEditingChildren.push({
        key: '/data-editing/portfolio-data',
        label: 'Portfoliodaten',
      });
    }

    if (modelingParametersChildren.length > 0) {
      if (modelingParametersChildren.length === 1) {
        dataEditingChildren.push(modelingParametersChildren[0]);
      } else {
        dataEditingChildren.push({
          key: '/data-editing/modeling-parameters',
          label: 'Modellierungsparameter',
          children: modelingParametersChildren,
        });
      }
    }

    if (dataEditingChildren.length > 0) {
      if (dataEditingChildren.length === 1) {
        newMenuItems.push(dataEditingChildren[0]);
      } else {
        newMenuItems.push({
          key: '/data-editing',
          label: 'Datenbearbeitung',
          children: dataEditingChildren,
        });
      }
    }

    const guidelinesChildren = [];

    if (client?.permissions.includes('get_ghg_asset_pathways')) {
      guidelinesChildren.push({
        key: '/guidelines/crrem',
        label: 'CRREM',
      });
    }

    if (guidelinesChildren.length > 0) {
      if (guidelinesChildren.length === 1) {
        newMenuItems.push(guidelinesChildren[0]);
      } else {
        newMenuItems.push({
          key: '/guidelines',
          label: 'Richtlinien',
          children: guidelinesChildren,
        });
      }
    }

    // Administration {Benutzerverwaltung, Kundenverwaltung}
    // If a parent menu item has only one child, the child is displayed directly
    if (adminRoles?.includes('benutzerverwaltung') || adminRoles?.includes('kundenverwaltung')) {
      const administrationChildren = [];

      if (adminRoles?.includes('benutzerverwaltung')) {
        administrationChildren.push({
          key: '/administration/users',
          label: 'Benutzerverwaltung',
        });
      }

      if (adminRoles?.includes('kundenverwaltung')) {
        administrationChildren.push({
          key: '/administration/clients',
          label: 'Kundenverwaltung',
        });
      }

      if (administrationChildren.length === 1) {
        newMenuItems.push(administrationChildren[0]);
      } else if (administrationChildren.length > 0) {
        newMenuItems.push({
          key: '/administration',
          label: 'Administration',
          children: administrationChildren,
        });
      }
    }

    setMenuItems(newMenuItems);
  };

  const handleClickMenuItem: MenuProps['onClick'] = (e) => {
    if (e.key === '/administration/users') navigate(`/administration/users`);
    else if (e.key === '/administration/clients') navigate(`/administration/clients`);
    else if (client) navigate(`/client/${client.id}${e.key}`);
    else navigate(e.key); // not used
  };

  const handleChangeClient = (clientId: string) => {
    changeClient(clientId);
    if (location.pathname.startsWith('/client')) {
      if (clientId)
        navigate(`/client/${clientId}/${location.pathname.split('/').slice(3).join('/')}`); // replace client id in url
      else navigate(`/welcome`); // client selection cleared
    } else if (!location.pathname.startsWith('/administration') && clientId) {
      navigate(`/client/${clientId}/report`);
    }
  };

  // Filter `option.label` match the user type `input`
  const filterClientsOptions = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#000',
        padding: '0 12px',
      }}
    >
      <div className="demo-logo" />
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={['2']}
        onClick={handleClickMenuItem}
        selectedKeys={[currentMenuItem]}
        items={menuItems as any}
        style={{ flex: 1, minWidth: 0, backgroundColor: '#000' }}
      />
      <div id="right" style={{ display: 'flex', alignItems: 'center' }}>
        {clients.length > 1 && (
          <>
            <Select
              value={client?.id}
              showSearch
              placeholder={'Bericht auswählen'}
              optionFilterProp="children"
              style={{ width: 160, marginRight: '12px' }}
              onSelect={handleChangeClient}
              filterOption={filterClientsOptions}
              options={clientsOptions}
              onClear={() => {
                changeClient(undefined);
                navigate(currentMenuItem);
              }}
            />
          </>
        )}
        <UserMenu />
      </div>
    </div>
  );
};

export default Navbar;
