import { QuestionCircleOutlined } from '@ant-design/icons';
import { Drawer, FloatButton } from 'antd';
import { FC, useState } from 'react';

const UsageInfoDrawer: FC<{ site: string }> = ({ site }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const usageInfo = () => {
    switch (site) {
      case 'Neuberechnung':
        return (
          <>
            <p>
              Auf dieser Seite können Sie eine Neuberechnungen der Daten ihres Berichts auslösen.
            </p>
            <p>
              Klicken Sie auf den Button <i>Berechnung starten</i>, um eine Neuberechnung
              auszulösen. Sobald die Neuberechnung abgeschlossen ist, wird der Bericht entsprechend
              aktualisiert.
            </p>
          </>
        );
      case 'Maßnahmenplanung Top-Down':
        return (
          <>
            <p>Auf dieser Seite können Sie Maßnahmen nach dem Top-Down Verfahren Konfigurieren.</p>
            <p>
              Um eine Maßnahme zu Bearbeiten klicken Sie auf das <i>+</i> Toggle, um die
              Konfigurtions-Felder der Maßnahme anzuzeigen und zu bearbeiten. Clicken Sie
              anschließend auf <i>Speichern</i>.{' '}
            </p>
          </>
        );
      case 'Maßnahmenplanung Bottom-Up':
        return (
          <>
            <p>Auf dieser Seite können Sie Maßnahmen nach dem Bottom-Up Verfahren Konfigurieren.</p>
            <p>
              Wählen Sie zunächst das Jahr der DataColl aus, für welche die Maßhnahmen konfiguriert
              werden sollen.
            </p>
            <p>
              Wählen Sie anschließend die Anlagen, die Sie bearbeiten möchten aus. Sie können dafür
              auch nach Wirtschaftseinheit (WE), Straße oder Key der Maßnahme suchen.
              <br />
              Hat eine Wirtschaftsanlage mehrere Keys zugeordnet, so können Sie diese einzeln
              anzeigen und auswählen oder alle Anlagen der Wirtschaftseinheit durch die
              entsprechende Checkbox der Wirtschaftseinheit auswählen.
            </p>
            <p>
              Wenn Sie sicher sind, dass in der Maßnahmen-Tabelle keine ungespeicherten Änderungen
              vorliegen, oder Sie diese verwerfen möchten, klicken Sie <i>Auswahl bestätigen</i>, um
              die Daten der Maßnahmen abzurufen.
              <br />
              <b>Nicht gespeicherte Änderungen in der Maßnahmen-Tabelle werden damit verworfen.</b>
            </p>
            <p>
              Wählen Sie zunächst das Szenario (A, B oder C) aus, für welches Sie die Maßnahmen
              bearbeiten möchten. Ungespeicherte Änderungen gehen nicht verloren, wenn Sie zwischen
              den Szenarios wechseln.
            </p>
            <p>
              Aktualisieren Sie die Werte für eine Anlage (repräsentiert bei Key), und klicken Sie
              auf Speichern, um die Änderungen zu speichern, sodass diese bei der nächsten
              Neuberechnung berücksichtigt werden.
            </p>
            <p>
              Sollte eine Maßnahme mit grauem Hintergrund und einer nicht ausgewählten Checkbox{' '}
              <i>isBottomUp</i> angezeigt werden, so können Änderungen zwar vorgenommen und
              gespeichert werden, diese wirken sich jedoch nicht auf den Bericht aus (außer Maßnahme
              wird von einer berechtigten Person als Bottom-Up konfiguriert).
            </p>
          </>
        );
      case 'b2zero Dashboard':
        return (
          <>
            <p>Auf dieser Seite können Sie ihren Power BI Bericht einsehen.</p>
            <p>
              Falls Sie Zugriff auf die Berichte mehrerer Kunden haben, bzw. Ihre Firma über mehrere
              Kunden modelliert wird, können Sie in der Navigationsleiste zwischen den gewünschten
              Kunden auswählen.
            </p>
            <p>
              Falls dem ausgewähltem Kunden mehrere Berichte zugeordnet sind können finden Sie unter
              dem Bericht eine Auswahl, um zwischen den Berichten zu wechseln.
            </p>
          </>
        );
      case 'Benutzerverwaltung':
        return (
          <>
            <p>Auf dieser Seite können Sie Benutzer verwalten.</p>
            <p>Um einen Benutzer zu bearbeiten, wählen Sie diesen zunächst aus.</p>
            <p>
              In der nun dargestellten Benutzer-Kunde-Rollen Tabelle können Sie dem Benutzer nun für
              ausgewählte Kunden Rollen zuweisen und diese anschließend speichern.
              <br />
              Wählen Sie <i>b2zero_dashboard</i>, um die Anzeige des Berichts zu erlauben.
              <br />
              Wählen Sie <i>neuberechnung</i>, um die Neuberechnung der Daten zu erlauben (und die
              entsprechende Seite freizuschalten).
              <br />
              Wählen Sie <i>massnahmenplanung_bottom_up</i>, um die Seite Maßnahmenplanung Bottom-Up
              freizuschalten.
              <br />
              Wählen Sie <i>massnahmenplanung_top_down</i>, um die Seite Maßnahmenplanung Top-Down
              freizuschalten.
              <br />
              Wählen Sie <i>massnahmenplanung_top_down</i>, um die Seite Maßnahmenplanung Top-Down
              freizuschalten.
              <br />
              Wählen Sie <i>modellierungsparameter_auswahl_eingabeparameter</i>, um die Seite
              Auswahl Eingabeparameter freizuschalten.
              <br />
              Wählen Sie <i>modellierungsparameter_auswahl_zeitreihen</i>, um die Seite Auswahl
              Eingabeparameter freizuschalten.
              <br />
              Wählen Sie <i>portfolio_daten</i>, um die Seite Portfoliodaten freizuschalten.
              <br />
              Wählen Sie <i>maintainer</i>, um kundenspezifische Maintainer-Seiten freizuschalten.
            </p>
            <p>
              Sie können auch Rollen für alle Clients hinzufügen oder entfernen, indem Sie die
              entsprechenden Checkboxen in der Zeile <i>Rollen für alle Clients hinzufügen</i> bzw.{' '}
              <i>Rollen für alle Clients entfernen</i> auswählen.
              <br />
              Da dies zu Anpassungen der Benutzer-Kunde-Rollen zwischen dem Benutzer mit jedem
              einzelnen der Kunden führt, können diese Änderungen <b>nicht rückgängig</b> gemacht
              werden, beziehungsweise nur für alle Kunden durch die entsprechende Aktion, oder
              einzeln für jeden Kunde.
            </p>
            <p>
              Unter der Eingabemaske zum Anpassen der Benutzer-Kunde-Rollen finden Sie eine
              Eingabemaske, um die globalen Benutzerdetails des aktuellen Benutzers anzupassen.
              <br />
              Durch Auswahl der Rolle <i>data_admin</i> erhält der Benutzer Zugriff auf
              Benutzerübergreifende datenbezogene Funktionen, wie das auslösen von Neuberechnungen
              für alle Kunden.
              <br />
              Durch Auswahl der Rolle <i>benutzerverwaltung</i> erhält der Benutzer Zugriff auf die
              Benuteradministration.
              <br />
              Durch Auswahl von <i>Account gesperrt</i> kann der Benutzer sich nicht mehr anmelden.
            </p>
          </>
        );
      case 'Kundenverwaltung':
        return (
          <>
            <p>Auf dieser Seite können Sie Kunden verwalten.</p>
            <p>Um einen Kunden zu bearbeiten, wählen Sie diesen zunächst aus.</p>
            <p>
              In dem nun dargestellten Eingabemasken Kunde bearbeiten und Berichte bearbeiten können
              Änderungen für den ausgewählten Kunde vorgenommen werden.
            </p>
            <p>
              Um einen Kunden anzulegen, legen sie zunächst einen Kunden in der Eingabemaske Kunde
              anlegen an. Anschließend können sie die Eingabemaske Berichte bearbeiten verwenden, um
              zugeordnete Berichte zu verwalten/anzulegen.
            </p>
          </>
        );

      case 'Portfoliodaten': // TODO
        return (
          <>
            <p>Auf dieser Seite können Sie die Portfoliodaten einsehen.</p>
            <p>
              Wählen Sie dafür das gewünschte Bezugsjahr, und die Kategorien, die sie einsehen
              möchten.
            </p>
            <p>Bei weiteren Fragen, bitte wenden Sie sich an den Support.</p>
          </>
        );

      case 'Daten Upload': // TODO
        return (
          <>
            <p>
              Auf dieser Seite können Sie die Portfoliodaten durch Hochladen einer neuen data_coll
              Tabelle ersetzen.
            </p>
            <p>
              Wählen Sie dafür die zu ersetzende data_coll aus, laden sie die neue data_coll hoch,
              beheben sie strukturelle und inhaltliche konflikte, bestätigen Sie den Upload.
            </p>
            <p>Bei weiteren Fragen, bitte wenden Sie sich an den Support.</p>
          </>
        );
      case 'Auswahl Eingabeparameter':
        return (
          <>
            <p>Auf dieser Seite können Sie die Auswahl Eingabeparameter bearbeiten.</p>
            <p>
              Klappen Sie dafür die zu bearbeitende Sektion aus, nehmen Sie die gewünschten
              Änderungen vor und bestätigen Sie die Eingeben durch den Speichern Button.
            </p>
          </>
        );
      case 'Auswahl Zeitreihen':
        return (
          <>
            <p>
              Auf dieser Seite können Sie die zu verwendenden Zeitreihen definieren und
              visualisieren.
            </p>
            <p>
              Definieren Sie dafür die gewünschten Zeitreihen in der Tabelle. Klicken Sie auf einen
              der Zeitreihen-Buttons im Tabellenheader, um die entsprechenden Optionen für diese
              Zeitreihe zu visualisieren.
            </p>
            <p>Die wichtigsten Funktionen dieser Seite sind:</p>
            <ul>
              <li>
                <strong>Kategorien filtern:</strong> Die Tabelle kann nach Kategorien gefiltert
                werden. Wählen Sie die gewünschten Kategorien aus, um die Tabelle entsprechend
                anzupassen.
              </li>
              <li>
                <strong>Zellen bearbeiten:</strong> Sie können die Zellen in der Tabelle bearbeiten,
                indem Sie auf sie klicken. Dies ermöglicht es Ihnen, die Werte direkt in der Tabelle
                zu ändern.
              </li>
              <li>
                <strong>Änderungen speichern:</strong> Sie können Ihre Änderungen speichern, indem
                Sie auf den Button "Speichern" klicken. Dies stellt sicher, dass Ihre Anpassungen
                dauerhaft übernommen werden.
              </li>
              <li>
                <strong>Graph aktualisieren:</strong> Der Graph wird basierend auf der ausgewählten
                Zeitreihenspalte und den in dieser Spalte getroffenen Auswahlen sowie den Auswahlen
                im Feld "Anzuzeigende Zeitreihen" aktualisiert.
              </li>
            </ul>
          </>
        );
      case 'CRREM':
        return (
          <>
            <p>
              Auf dieser Seite können Sie die CRREM Pfade (Carbon Risk Real Estate Monitor) für
              verschiedene Assetklassen einsehen.
            </p>
            <p>Die Seite bietet folgende Filtermöglichkeiten:</p>
            <ul>
              <li>
                <strong>Assetklasse:</strong> Wählen Sie eine oder mehrere Assetklassen aus (z.B.
                Wohngebäude, Bürogebäude, Hotels).
              </li>
              <li>
                <strong>Klimapfad:</strong> Wählen Sie zwischen dem 1,5°C und 2°C Klimapfad.
              </li>
              <li>
                <strong>Bezugsfläche:</strong> Wählen Sie zwischen Wohnfläche (nur für Multi
                Residential verfügbar) und IPMS2 (Nutzfläche, für alle Assetklassen verfügbar).
              </li>
              <li>
                <strong>Land:</strong> Wählen Sie das Land für welches Sie die Dekarbonisierung-
                Pfade einsehen möchten. Wird kein Wert ausgewählt, wird Deutschland als Standard
                verwendet. Es können auch mehrere Länder gleichzeitig ausgewählt werden.
              </li>
              <li>
                <strong>Zieljahr:</strong> Wählen Sie das Zieljahr für die Dekarbonisierung (2045
                oder 2050). Wird kein Wert ausgewählt, wird 2045 als Standard verwendet. Es können
                auch beide Werte gleichzeitig ausgewählt werden.
              </li>
            </ul>
            <p>
              Die Daten werden in zwei Diagrammen dargestellt:
              <ul>
                <li>CO₂-Zielwert (kg/m²a)</li>
                <li>Endenergie-Zielwert (kWh/m²a)</li>
              </ul>
              Die Legende zeigt die Kombination aus Assetklasse, Klimapfad und Zieljahr.
            </p>
          </>
        );
      default:
        return (
          <>
            <p>
              Für die aktuelle Seite liegen keine Nutzungsinformationen vor. Bitte wenden Sie sich
              an den Support, um Hilfe zu erhalten.
            </p>
          </>
        );
    }
  };

  return (
    <>
      <FloatButton
        icon={<QuestionCircleOutlined />}
        type="default"
        style={{ right: 24 }}
        onClick={showDrawer}
      />
      <Drawer
        title={`Nutzungsinformationen für ${site}`}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
      >
        {usageInfo()}
      </Drawer>
    </>
  );
};

export default UsageInfoDrawer;
