import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// MSAL imports
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from './authConfig';
import { ConfigProvider } from 'antd';

import * as Sentry from '@sentry/react';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      // @ts-ignore
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/gateway\.victoriousglacier-6eb82086\.westeurope\.azurecontainerapps\.io\/api/,
      /^https:\/\/api\.b2zero\.cloud\/api/,
      /^https:\/\/co2tooldevb2c\.b2clogin\.com\/co2tooldevb2c\.onmicrosoft\.com\/b2c_1_co2tool_user\/v2\.0\/\.well-known/,
      /^https:\/\/login\.b2zero\.cloud\/ewusco2toolproduction\.onmicrosoft\.com\/b2c_1_login_production\/v2\.0\/\.well-known/,
      /^https:\/\/content\.powerapps\.com/,
      /^https:\/\/app\.powerbi\.com/,
      /^https:\/\/graph\.microsoft\.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.REACT_APP_ENV || 'development',
  });

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <React.StrictMode>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: '#33CC00',
            fontFamily:
              'esbuildneutral-regular, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
            // Alias Token
            // colorBgContainer: '#f6ffed',
          },
        }}
      >
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </ConfigProvider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
