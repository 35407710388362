import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { Alert, message, Typography, Tooltip } from 'antd';
import { apiUrl } from '../../../common/url';
import AssetPathwayFilters from './AssetPathwayFilters';
import { generateColors } from '../../../common/colors';
import LineChartCard from '../../../components/common/LineChartCard';

export interface FilterOptions {
  assetClass: string[];
  pathway: number[];
  yearEnd: number[];
  referenceArea: string[];
  country: string[];
}

interface PathwayDataPoint {
  index: number;
  year: number;
  pathway: number;
  country: string;
  assetClass: string;
  co2Target: number;
  endEnergyTarget: number;
  referenceArea: string;
  version: number;
  yearEnd: number;
  pathwayId: string;
}

interface SelectedFilters {
  version?: number;
  assetClass?: string[];
  pathway?: number[];
  yearEnd?: number[];
  referenceArea?: string[];
  country?: string[];
}

const mapCountryNameToCode = (country: string) => {
  switch (country) {
    case 'Deutschland':
      return 'DE';
    case 'Niederlande':
      return 'NL';
    case 'Österreich':
      return 'AT';
    default:
      return country.slice(0, 2).toUpperCase();
  }
};

interface CrremGuidelinesDashboardProps {
  client: { id: string; token: string };
}

const CrremGuidelinesDashboard: React.FC<CrremGuidelinesDashboardProps> = ({ client }) => {
  const [filterOptions, setFilterOptions] = useState<FilterOptions>();
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    yearEnd: [2045],
    country: ['Deutschland'],
    referenceArea: ['IPMS2'],
  });
  const [pathwayData, setPathwayData] = useState<PathwayDataPoint[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const colorMap = useMemo(() => {
    const uniquePathwayIds = Array.from(new Set(pathwayData.map((d) => d.pathwayId)));
    const colors = generateColors(uniquePathwayIds.length);

    const map = new Map<string, string>();
    uniquePathwayIds.forEach((id, index) => {
      map.set(id, colors[index]);
    });

    return map;
  }, [pathwayData]);

  const yearDomain = useMemo((): [number, number] => {
    const maxYear = selectedFilters.yearEnd?.length ? Math.max(...selectedFilters.yearEnd) : 2045;
    return [2020, maxYear];
  }, [selectedFilters.yearEnd]);

  useEffect(() => {
    setLoading(true);
    setError(null);

    axios
      .get(`${apiUrl}/b2zero/${client.id}/get_ghg_asset_pathways_filter_options`, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        setFilterOptions(response.data);
      })
      .catch((error) => {
        setError('Error fetching filter options: ' + error.message);
        message.error('Error fetching filter options: ' + error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [client.id, client.token]);

  useEffect(() => {
    if (!filterOptions) return;

    setLoading(true);
    setError(null);

    axios
      .post(`${apiUrl}/b2zero/${client.id}/get_ghg_asset_pathways`, selectedFilters, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        const filteredData = response.data.filter(
          (d: PathwayDataPoint) => d.year >= yearDomain[0] && d.year <= yearDomain[1]
        );
        setPathwayData(filteredData);
      })
      .catch((error) => {
        setError('Error fetching pathway data: ' + error.message);
        message.error('Error fetching pathway data: ' + error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedFilters, client.id, client.token, yearDomain, filterOptions]);

  const handleFilterChange = (value: any, filterName: keyof SelectedFilters) => {
    setSelectedFilters((prev) => {
      const newFilters = { ...prev };
      newFilters[filterName] = value?.length === 0 ? undefined : value;
      return newFilters;
    });
  };

  if (loading && !filterOptions) {
    return <Alert message="Loading..." type="info" />;
  }

  const renderChart = (dataKey: 'co2Target' | 'endEnergyTarget', title: string, unit: string) => (
    <LineChartCard
      title={title}
      unit={unit}
      data={pathwayData}
      loading={loading}
      dataKey={dataKey}
      getLineProps={({ firstPoint, id }: { firstPoint: PathwayDataPoint; id: string }) => ({
        name: `${firstPoint.assetClass} - ${firstPoint.referenceArea} (${firstPoint.pathway}°C, ${mapCountryNameToCode(firstPoint.country)})`,
        stroke: colorMap.get(id) || '#33cc00',
      })}
      xAxisConfig={{
        dataKey: 'year',
        domain: yearDomain,
      }}
    />
  );

  return (
    <>
      <Tooltip title="Carbon Risk Real Estate Monitor" placement="bottomLeft">
        <Typography.Title level={2}>CRREM</Typography.Title>
      </Tooltip>
      <div style={{ padding: '24px' }}>
        <AssetPathwayFilters
          filterOptions={
            filterOptions && selectedFilters.version === 203
              ? {
                  ...filterOptions,
                  pathway: filterOptions.pathway.filter((p) => p !== 2.0),
                }
              : filterOptions
          }
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
        />
        {error ? (
          <Alert message={error} type="error" style={{ marginBottom: 24 }} />
        ) : (
          <>
            {renderChart('co2Target', 'CO₂-Zielwert', 'kg/m²a')}
            {renderChart('endEnergyTarget', 'Endenergie-Zielwert', 'kWh/m²a')}
          </>
        )}
      </div>
    </>
  );
};

export default CrremGuidelinesDashboard;
