import React, { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, Form, message, Modal, Table, Tooltip, Typography } from 'antd';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { apiUrl } from '../../../common/url';
import { ColumnsType, ColumnType, ColumnGroupType } from 'antd/es/table';
import EditableCell from './EditableCell';
import ClientSelection from './ClientSelection';
import CommoditiesTimeseries from './CommoditiesTimeseries';
import { DeleteOutlined } from '@ant-design/icons';

interface DataType {
  [key: string]: any;
}

interface FixedColumn {
  client_id: number;
  name: string;
  [key: string]: any;
  isTemplate?: boolean;
  editable?: boolean;
}

interface CategoryColumn {
  name: string;
  description?: string;
  options?: any[];
}

interface CategoryData {
  title: string;
  description: string;
  columns: CategoryColumn[];
  data: DataType[];
}

interface BackendResponse {
  fixedColumns: FixedColumn[];
  categories: CategoryData[];
  allOptions: string[];
}

interface CustomColumnType<T> extends ColumnType<T> {
  editable?: boolean;
  options?: { label: string; value: any }[];
  allOptions?: { label: string; value: any }[];
}

interface CustomColumnGroupType<T> extends ColumnGroupType<T> {
  children: CustomColumnType<T>[];
  editable?: boolean;
}

type CustomColumnsType<T> = (CustomColumnType<T> | CustomColumnGroupType<T>)[];

interface TimeSeriesSelectionProps {
  client: { id: string; name?: string; token: string; permissions: string[] };
  clients: { id: string; reportType?: string; name: string }[];
  adminRoles?: string[];
  adminToken?: string;
}

const TimeSeriesSelection: React.FC<TimeSeriesSelectionProps> = ({
  client,
  clients,
  adminRoles,
  adminToken,
}) => {
  const [visibleCategories, setVisibleCategories] = useState<string[]>([]);
  const [data, setData] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
  const [totalWidth, setTotalWidth] = useState(0);
  const [selectedClients, setSelectedClients] = useState<number[]>([]);
  const [allOptions, setAllOptions] = useState<{ label: string; value: string }[]>([]);
  const [selectedTimeseriesGroup, setSelectedTimeseriesGroup] = useState<string>('');
  const [assignedTimeseries, setAssignedTimeseries] = useState<
    { key: string; clients: { id: string; name: string }[] }[]
  >([]);
  const [timeseriesToDisplay, setTimeseriesToDisplay] = useState<string[]>([]);
  const [shouldCallHeaderClick, setShouldCallHeaderClick] = useState<boolean>(false);

  const handleChangeSelectedClients = (selectedClients: { label: string; value: number }[]) => {
    const selectedClientIds = selectedClients.map((user) => user.value);
    setSelectedClients(selectedClientIds);
    if (selectedTimeseriesGroup !== '') {
      handleHeaderClick(selectedTimeseriesGroup);
    }
  };

  const fetchTimeseries = () => {
    let client_ids: number[] = selectedClients.length > 1 ? selectedClients : [Number(client.id)];
    const templateClientId = 1;
    let includeEditableTemplateClient = false;

    // Ensure client_id 1 is included
    if (!client_ids.includes(templateClientId)) {
      client_ids.push(templateClientId);
    } else {
      includeEditableTemplateClient = true;
    }

    const queryUrl = adminRoles?.includes('data_admin')
      ? `${apiUrl}/admin/clients_selected_asm/get_clients_selected_asm`
      : `${apiUrl}/b2zero/${client.id}/get_clients_selected_asm`;
    const authToken = adminRoles?.includes('data_admin') ? adminToken : client.token;
    axios
      .post(queryUrl, { client_ids }, { headers: { Authorization: `Bearer ${authToken}` } })
      .then((response) => {
        const { fixedColumns, categories, allOptions } = response.data as BackendResponse;
        setAllOptions(allOptions.map((option) => ({ label: option, value: option })) || []);
        const fixedColumnsDef: CustomColumnsType<DataType> = Object.keys(fixedColumns[0]).map(
          (key, index) => ({
            title: key,
            dataIndex: key,
            key: `fixed-${key}-${index}`,
            fixed: 'left' as 'left',
            width: key === 'client_id' ? 75 : 200,
          })
        );

        const categoryColumns: CustomColumnsType<DataType> = categories.map((category) => ({
          title: <Tooltip title={category.description}>{category.title}</Tooltip>,
          key: category.title,
          children: category.columns.map((col) => ({
            title: <Tooltip title={col.description}>{col.name}</Tooltip>,
            dataIndex: col.name,
            key: `${category.title}-${col.name}`,
            editable: true,
            options: col.options?.map((option) => ({ label: option, value: option })) || [],
          })),
        }));

        let data = fixedColumns.map((fixedCol, fixedColIndex) => ({
          ...fixedCol,
          key: `fixedCol-${fixedCol.client_id}-${fixedColIndex}`,
          ...categories.reduce((acc, category) => {
            const categoryData = category.data.find(
              (data) => data.client_id === fixedCol.client_id
            );
            return { ...acc, ...categoryData };
          }, {}),
        }));

        const templateClientIndex = data.findIndex((row) => row.client_id === templateClientId);

        if (templateClientIndex !== -1) {
          if (includeEditableTemplateClient) {
            // Copy the result data for template client and modify the copy
            const templateRow = {
              ...data[templateClientIndex],
              client_id: -1,
              key: `template`,
              editable: false,
              isTemplate: true,
            };
            data.unshift(templateRow); // Add the template row to the beginning
          } else {
            // Modify the existing data for client_id templateClientId
            data[templateClientIndex] = {
              ...data[templateClientIndex],
              key: `template`,
              editable: false,
              isTemplate: true,
            };
            // Move the modified template row to the beginning
            const [templateRow] = data.splice(templateClientIndex, 1);
            data.unshift(templateRow);
          }
        } else {
          message.warning('Keine Template Daten verfügbar. Bitte überprüfen Sie die Datenbank.');
        }

        // Check for missing client data and copy from template
        client_ids.forEach((clientId) => {
          if (clientId !== templateClientId) {
            const clientDataIndex = data.findIndex((row) => row.client_id === clientId);
            if (clientDataIndex === -1 && templateClientIndex !== -1) {
              const newClient = clients.find((c) => Number(c.id) === clientId);
              const newClientData = {
                ...data[templateClientIndex],
                client_id: clientId,
                name: newClient ? newClient.name : `Client ${clientId}`, // Ensure name is always a string
                key: `new-${clientId}`,
                isTemplate: false,
                isNew: true,
              };
              data.push(newClientData);
            }
          }
        });

        setColumns([...fixedColumnsDef, ...categoryColumns]);
        setData(data);
        const visibleCategoriesNew = categories.length > 0 ? [categories[0].title] : [];
        setVisibleCategories(visibleCategoriesNew);
        setTotalWidth(calculateTotalWidth(visibleCategoriesNew));
      })
      .catch((error) => {
        message.error('Fehler beim Abruf der Zeitreihen Daten: ' + error.message);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Abrufen der Zeitreihen Daten: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  useEffect(() => {
    fetchTimeseries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.id, selectedClients]);

  const handleChangeCell = (row: DataType) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.key === item.key);
    let shouldCallHeaderClick = false;

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });

      // Check if the value in the selectedTimeseriesGroup column has changed
      if (
        selectedTimeseriesGroup &&
        item[selectedTimeseriesGroup] !== row[selectedTimeseriesGroup]
      ) {
        shouldCallHeaderClick = true;
      }

      setData(newData);
    } else {
      newData.push(row);

      // Check if the new row has a value in the selectedTimeseriesGroup column
      if (selectedTimeseriesGroup && row[selectedTimeseriesGroup] !== undefined) {
        shouldCallHeaderClick = true;
      }

      setData(newData);
    }

    // Set the state to trigger useEffect
    setShouldCallHeaderClick(shouldCallHeaderClick); // works but resets timeseriesToDisplay,
    // alternative approach: more custom logic, like only adding it, or only changing automatically, if the affected timeseries were not touched by manual selection
  };

  useEffect(() => {
    if (shouldCallHeaderClick && selectedTimeseriesGroup) {
      handleHeaderClick(selectedTimeseriesGroup);
      setShouldCallHeaderClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, shouldCallHeaderClick, selectedTimeseriesGroup]);

  const hasChildren = (
    col: CustomColumnType<DataType> | CustomColumnGroupType<DataType>
  ): col is CustomColumnGroupType<DataType> => {
    return (col as CustomColumnGroupType<DataType>).children !== undefined;
  };

  const mergedColumns = columns.map((col) => {
    if (!hasChildren(col)) {
      return col;
    }
    return {
      ...col,
      children: col.children.map((child: CustomColumnType<DataType>) => ({
        ...child,
        onCell: (record: DataType) => ({
          record,
          editable: child.editable,
          dataIndex: child.dataIndex,
          title: child.title,
          handleChangeCell,
          options: child.options,
          allOptions: allOptions,
        }),
        onHeaderCell: child.editable
          ? () => ({
              onClick: () => handleHeaderClick(child.title as string),
            })
          : undefined,
        title: (
          <Tooltip title="Klicken Sie hier, um diese Zeitreihen zu visualisieren.">
            <Button>{child.title as string}</Button>
          </Tooltip>
        ),
      })),
    };
  });

  const filteredColumns = [
    ...mergedColumns.filter((col) =>
      hasChildren(col) ? visibleCategories.includes(col.key as string) : true
    ),
    {
      title: 'Aktion',
      key: 'action',
      fixed: 'right' as 'right',
      width: 170,
      render: (_: any, record: DataType) => {
        if (!record.isTemplate) {
          if (record.isNew) {
            return (
              <Button type="primary" onClick={() => createClientsSelectedRow(record)}>
                Erstellen
              </Button>
            );
          } else {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button type="primary" onClick={() => saveChanges(record)}>
                  Speichern
                </Button>
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  style={{ marginLeft: '10px' }}
                  onClick={() => handleDelete(record)}
                />
              </div>
            );
          }
        }
        return null;
      },
    },
  ];

  const handleDelete = (row: DataType) => {
    Modal.confirm({
      title: `Möchten Sie die gesamte Konfiguration (für alle Kategorien), welche Zeitreihen für ${row.name} verwendet werden wirklich löschen?`,
      content: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      okText: 'Löschen',
      okType: 'danger',
      cancelText: 'Abbrechen',
      onOk: () => {
        const queryUrl = adminRoles?.includes('data_admin')
          ? `${apiUrl}/admin/clients_selected_asm/delete_clients_selected_asm`
          : `${apiUrl}/b2zero/${client.id}/delete_clients_selected_asm`;
        const authToken = adminRoles?.includes('data_admin') ? adminToken : client.token;
        axios
          .post(
            queryUrl,
            { client_id: row.client_id },
            {
              headers: { Authorization: `Bearer ${authToken}` },
            }
          )
          .then(() => {
            message.success('Konfiguration wurde erfolgreich gelöscht.');

            const templateClientId = 1;
            const templateClientIndex = data.findIndex(
              (item) => item.client_id === templateClientId
            );

            // TODO: avoid code duplication, see fetchTimeseries
            if (templateClientIndex !== -1) {
              const newClient = clients.find((c) => Number(c.id) === row.client_id);
              const newClientData = {
                ...data[templateClientIndex],
                client_id: row.client_id,
                name: newClient ? newClient.name : `Client ${row.client_id}`, // Ensure name is always a string
                key: `new-${row.client_id}`,
                isTemplate: false,
                isNew: true,
              };

              const newData = data.map((item) => (item.key === row.key ? newClientData : item));
              setData(newData);
            } else {
              message.warning(
                'Keine Template Daten verfügbar. Bitte überprüfen Sie die Datenbank.'
              );
              const newData = data.filter((item) => item.key !== row.key);
              setData(newData);
            }
          })
          .catch((error) => {
            message.error('Fehler beim Löschen des Eintrags: ' + error.message, 5);
            Sentry.withScope((scope) => {
              scope.setLevel('warning');
              scope.setExtra('hint', 'Error while deleting data:' + error.message);
              scope.setExtra('row', row);
              scope.setExtra('queryUrl', queryUrl);
              Sentry.captureException(error);
            });
          });
      },
    });
  };

  const calculateTotalWidth = (visibleCategories: string[]) => {
    const fixedColumnsWidth = columns
      .filter((col) => !hasChildren(col))
      .reduce((acc, col) => {
        if (col.title === 'key') {
          return acc + 75;
        }
        return acc + 200;
      }, 0);

    const visibleCategoryColumnsWidth = columns
      .filter((col) => hasChildren(col) && visibleCategories.includes(col.key as string))
      .reduce((acc, col) => {
        if (hasChildren(col)) {
          return acc + (col.children?.length || 0) * 250;
        }
        return acc;
      }, 0);

    return fixedColumnsWidth + visibleCategoryColumnsWidth;
  };

  const saveChanges = (row: DataType) => {
    const { key, isTemplate, isNew, editable, ...dataToSend } = row;
    const queryUrl =
      Number(client.id) === row.client_id
        ? `${apiUrl}/b2zero/${client.id}/update_client_selected_asm`
        : `${apiUrl}/admin/clients_selected_asm/update_client_selected_asm`;
    const authToken = Number(client.id) === row.client_id ? client.token : adminToken;
    axios
      .post(queryUrl, dataToSend, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => {
        message.success('Änderungen erfolgreich gespeichert.');
      })
      .catch((error) => {
        message.error('Fehler beim Speichern der Änderungen: ' + error.message, 5);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Error while saving data:' + error.message);
          scope.setExtra('row', row);
          scope.setExtra('queryUrl', queryUrl);
          Sentry.captureException(error);
        });
      });
  };

  const createClientsSelectedRow = (row: DataType) => {
    const { key, isTemplate, isNew, editable, ...dataToSend } = row;
    const queryUrl =
      Number(client.id) === row.client_id
        ? `${apiUrl}/b2zero/${client.id}/create_client_selected_asm`
        : `${apiUrl}/admin/clients_selected_asm/create_client_selected_asm`;
    const authToken = Number(client.id) === row.client_id ? client.token : adminToken;
    axios
      .post(queryUrl, dataToSend, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => {
        message.success('Änderungen erfolgreich gespeichert.');
        // Remove the isNew field from the row after successful creation
        const newData = [...data];
        const index = newData.findIndex((item) => row.key === item.key);
        if (index > -1) {
          newData[index] = { ...newData[index], isNew: undefined };
          setData(newData);
        }
      })
      .catch((error) => {
        message.error('Fehler beim Speichern der Änderungen: ' + error.message, 5);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Error while saving data:' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  const handleHeaderClick = (columnName: string) => {
    columnName =
      typeof columnName === 'string'
        ? columnName
        : (columnName as React.ReactElement).props.children;
    setSelectedTimeseriesGroup(columnName);

    const findColumn = (
      columns: Array<CustomColumnType<DataType> | CustomColumnGroupType<DataType>>,
      columnName: string
    ) => {
      for (const col of columns) {
        if ('children' in col) {
          const childColumn = col.children.find((child) => child.dataIndex === columnName);
          if (childColumn) {
            return childColumn;
          }
        }
      }
      return undefined;
    };

    const column = findColumn(columns, columnName);
    if (column && 'options' in column) {
      const options = column?.options?.map((option) => option.value) || [];

      const selectedValues = data.reduce(
        (acc, row) => {
          const timeseries = row[column.dataIndex as keyof DataType];
          if (timeseries !== undefined) {
            if (!acc[timeseries]) {
              acc[timeseries] = { key: timeseries, clients: [] };
            }
            acc[timeseries].clients.push({ id: row.client_id.toString(), name: row.name });
          }
          return acc;
        },
        {} as Record<string, { key: string; clients: { id: string; name: string }[] }>
      );

      const assignedTimeseries = Object.values(selectedValues);
      setAssignedTimeseries(assignedTimeseries);
      console.log('setAssignedTimeseries', assignedTimeseries);

      const assignedTimeseriesKeys = assignedTimeseries.map((ts) => ts.key);
      const combinedTimeseries = Array.from(new Set([...options, ...assignedTimeseriesKeys]));
      setTimeseriesToDisplay(combinedTimeseries);
    } else {
      setTimeseriesToDisplay([]);
      setAssignedTimeseries([]);
    }
  };

  const handleChangeTimeseriesToDisplay = (timeseriesToDisplayNames: string[]) => {
    setTimeseriesToDisplay(timeseriesToDisplayNames);

    const findColumn = (
      columns: Array<CustomColumnType<DataType> | CustomColumnGroupType<DataType>>,
      columnName: string
    ) => {
      for (const col of columns) {
        if ('children' in col) {
          const childColumn = col.children.find((child) => child.dataIndex === columnName);
          if (childColumn) {
            return childColumn;
          }
        }
      }
      return undefined;
    };

    // Ensure the column is found based on the latest selection
    const column = findColumn(columns, selectedTimeseriesGroup);
    if (!column) return;

    const updatedAssignedTimeseries = timeseriesToDisplayNames
      .map((timeseries) => ({
        key: timeseries,
        clients: data
          .filter((row) => row[column.dataIndex as keyof DataType] === timeseries)
          .map((row) => ({ id: row.client_id.toString(), name: row.name })),
      }))
      .filter(
        (timeseries) =>
          timeseries.clients.length > 0 && timeseriesToDisplayNames.includes(timeseries.key)
      );
    setAssignedTimeseries(updatedAssignedTimeseries);
  };

  return client === undefined ? (
    <div>Please select a client.</div>
  ) : (
    <>
      {(clients.length < 2 || !adminRoles?.includes('data_admin')) && (
        <Typography.Title level={1}>Auswahl Zeitreihen für {client.name}</Typography.Title>
      )}

      {clients?.length > 1 && adminToken && adminRoles?.includes('data_admin') && (
        <>
          <Typography.Title level={1}>Auswahl Zeitreihen</Typography.Title>
          <ClientSelection
            client={client}
            clients={clients}
            handleChangeSelectedClients={handleChangeSelectedClients}
            adminToken={adminToken}
          />
        </>
      )}
      <style>
        {`
          .template-row td {
            background-color: #f0f0f0 !important; /* Light gray background color */
          }
        `}
      </style>
      <div style={{ marginBottom: Math.ceil(data.length / 10) <= 1 ? '30px' : '0' }}>
        <Table
          columns={filteredColumns as (ColumnGroupType<DataType> | ColumnType<DataType>)[]}
          dataSource={data}
          rowKey="client_id"
          bordered
          scroll={{ x: totalWidth }}
          rowClassName={(record) => (record.isTemplate ? 'template-row' : '')}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={Math.ceil(data.length / 10) > 1 ? { pageSize: 10 } : false}
          title={() => (
            <>
              <Typography.Title level={3} style={{ margin: 0 }}>
                Auswahl Zeitreihen
              </Typography.Title>
              <Form.Item label="Kategorien" style={{ marginBottom: 0 }}>
                <Checkbox.Group
                  options={columns.filter(hasChildren).map((col, index) => ({
                    label: col.title as React.ReactNode,
                    value: col.key as string,
                    key: `checkbox-${index}`,
                  }))}
                  value={visibleCategories}
                  onChange={(checkedValues) => {
                    setVisibleCategories(checkedValues as string[]);
                    setTotalWidth(calculateTotalWidth(checkedValues));
                  }}
                />
              </Form.Item>
              <Alert
                description={
                  <>
                    Klicken Sie auf einen Eintrag in "Auswahl Zeitreihen" und wählen Sie die
                    gewünschte Zeitreihe aus dem Dropdownmenü.
                    <br />
                    Als Referenz und unsere Empfehlung dienen Ihnen die Einträge für die "Muster
                    GmbH".
                  </>
                }
                type="info"
              />
            </>
          )}
        />
      </div>
      <CommoditiesTimeseries
        selectedTimeseriesGroup={selectedTimeseriesGroup}
        assignedTimeseries={assignedTimeseries}
        handleChangeTimeseriesToDisplay={handleChangeTimeseriesToDisplay}
        timeseriesToDisplay={timeseriesToDisplay}
        adminToken={adminToken}
        adminRoles={adminRoles}
        client={client}
        allOptions={allOptions}
      />
    </>
  );
};

export default TimeSeriesSelection;
