import React, { useEffect, useState } from 'react';
import { Checkbox, Table, Button, Form, InputNumber, message } from 'antd';
import type { TableColumnsType } from 'antd';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { apiUrl } from '../../common/url';

interface MeasureType {
  key: React.Key;
  measure: string;
  scenarioA: boolean;
  scenarioB: boolean;
  scenarioC: boolean;
  conditions: Array<{
    label: string;
    description?: string;
    value: any;
    type: string;
    options?: Array<string | number>;
    validation?: { min?: number; max?: number };
  }>; // when type== selection then options required
}

interface ActionPlanningTopDownProps {
  client: { id: string; name?: string; token: string; permissions: string[] };
}

const ActionPlanningTopDown: React.FC<ActionPlanningTopDownProps> = ({ client }) => {
  const [dataSource, setDataSource] = useState<MeasureType[]>([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/b2zero/${client.id}/get_top_down_measures`, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        setDataSource(response.data);
      })
      .catch((error) => {
        message.error('Error fetching top-down measures: ' + error.message, 3);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Error fetching top-down measures: ' + error.message);
          Sentry.captureException(error);
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (key: React.Key, scenario: string) => {
    setDataSource(
      dataSource.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            [`scenario${scenario}` as keyof MeasureType]:
              !row[`scenario${scenario}` as keyof MeasureType],
          };
        }
        return row;
      })
    );
  };

  const handleChangeCondition = (key: React.Key, label: string, value: number | undefined) => {
    setDataSource(
      dataSource.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            conditions: row.conditions.map((condition) =>
              condition.label === label ? { ...condition, value } : condition
            ),
          };
        }
        return row;
      })
    );
  };

  const saveChanges = (key: React.Key) => {
    axios
      .post(
        `${apiUrl}/b2zero/${client.id}/update_top_down_measure/${key}`,
        {
          ...dataSource.find((row) => row.key === key),
        },
        { headers: { Authorization: `Bearer ${client.token}` } }
      )
      .then((response) => {
        message.success('Änderungen erfolgreich gespeichert.');
        setDataSource(dataSource.map((row) => (row.key === key ? response.data : row)));
      })
      .catch((error) => {
        message.error('Fehler beim Speichern der Änderungen: ' + error.message);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Speichern der Änderungen: ' + error.message);
          Sentry.captureException(error);
        });
        console.error('Error while saving data:', error.message);
      });
  };

  const columns: TableColumnsType<MeasureType> = [
    { title: 'Maßnahme', dataIndex: 'measure', key: 'measure' },
    {
      title: 'Aktive Analysen (Anwendug für Szenario)',
      dataIndex: '',
      key: 'activeAnalyses',
      render: (row) => {
        return (
          <>
            <Checkbox checked={row.scenarioA} onChange={() => handleCheckboxChange(row.key, 'A')}>
              A
            </Checkbox>
            <Checkbox checked={row.scenarioB} onChange={() => handleCheckboxChange(row.key, 'B')}>
              B
            </Checkbox>
            <Checkbox checked={row.scenarioC} onChange={() => handleCheckboxChange(row.key, 'C')}>
              C
            </Checkbox>
          </>
        );
      },
    },
    {
      title: 'Änderungen speichern',
      dataIndex: '',
      key: 'saveChanges', // TODO: trigger form validation, or move save to inside the form
      render: (val, row) => {
        return (
          <Button type="primary" onClick={() => saveChanges(row.key)}>
            Speichern
          </Button>
        );
      },
    },
  ];

  return client === undefined ? (
    <div>Please select a client.</div>
  ) : (
    <>
      <h1>{client.name}</h1>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                {record.conditions.map((condition, index) => (
                  <Form.Item
                    key={`${record.key}-${index}`}
                    label={condition.label}
                    name={condition.label}
                    tooltip={condition.description && condition.description}
                    // rules={[ // would be great to have this, but it's not applicable here because submit button is outside of form,
                    //  thus automatic updates to min/max if user enters invalid values are not possible, see InputNumber
                    //   {
                    //     type: 'number',
                    //     ...(condition.validation?.min !== undefined && { min: condition.validation.min }),
                    //     ...(condition.validation?.max !== undefined && { max: condition.validation.max }),
                    //     message: `Value must be between ${condition.validation?.min ?? '-∞'} and ${condition.validation?.max ?? '∞'}`,
                    //   },
                    // ]}
                  >
                    <InputNumber
                      value={condition.value}
                      {...(condition.validation?.min !== undefined && {
                        min: condition.validation.min,
                      })}
                      {...(condition.validation?.max !== undefined && {
                        max: condition.validation.max,
                      })}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        if (
                          trimmedValue === '' ||
                          trimmedValue === null ||
                          trimmedValue === undefined
                        ) {
                          return;
                        }
                        const value = Number(trimmedValue);
                        if (isNaN(value)) {
                          message.error(
                            `Ungültiger Wert für ${condition.label}: ${trimmedValue}. Bitte geben Sie eine Zahl ein.`,
                            6
                          );
                          return;
                        }

                        if (
                          condition.validation?.min !== undefined &&
                          value < condition.validation.min
                        ) {
                          message.warning(
                            `Der Wert für ${condition.label} wurde auf das Minimum von ${condition.validation.min} gesetzt.`,
                            6
                          );
                        }
                        if (
                          condition.validation?.max !== undefined &&
                          value > condition.validation.max
                        ) {
                          message.warning(
                            `Der Wert für ${condition.label} wurde auf das Maximum von ${condition.validation.max} gesetzt.`,
                            6
                          );
                        }
                      }}
                      onChange={(value) =>
                        handleChangeCondition(record.key, condition.label, value)
                      }
                      changeOnWheel
                    />
                  </Form.Item>
                ))}
              </Form>
            );
          },
        }}
        dataSource={dataSource}
      />
    </>
  );
};

export default ActionPlanningTopDown;
