/**
 * Generates an array of visually distinct colors.
 *
 * This function uses the golden angle approximation to generate a sequence of
 * colors that are visually distinct from each other. The colors are returned
 * as an array of HSL strings.
 */
export const generateColors = (count: number): string[] => {
  // Generate visually distinct colors
  const colors = [];
  for (let i = 0; i < count; i++) {
    const hue = (i * 137.508) % 360; // Golden angle approximation
    colors.push(`hsl(${hue}, 70%, 50%)`);
  }
  return colors;
};
