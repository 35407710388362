import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Alert, Form, message, Select, Typography, Tooltip } from 'antd';
import { apiUrl } from '../../../common/url';

interface Coefficient {
  year: number;
  value: number;
}

interface Timeseries {
  name: string;
  coefficients: Coefficient[];
}

interface CommoditiesTimeseriesProps {
  selectedTimeseriesGroup?: string;
  assignedTimeseries: { key: string; clients: { id: string; name: string }[] }[];
  handleChangeTimeseriesToDisplay: (assignedTimeseries: string[]) => void;
  timeseriesToDisplay: string[];
  adminToken?: string;
  adminRoles?: string[];
  client: { id: string; name?: string; token: string; permissions: string[] };
  allOptions: { label: string; value: string }[];
}

// Note: only neon green, blue, black, red are part of corporate design with 100%, 50%, 25% opacity
const assignedColors = [
  '#39FF14FF',
  '#0000FFFF',
  '#008000FF',
  '#800080FF', // Neon Green, Blue, Dark Green, Purple with 100% opacity
  '#39FF1480',
  '#0000FF80',
  '#00800080',
  '#80008080', // Neon Green, Blue, Dark Green, Purple with 50% opacity
  '#39FF14BF',
  '#0000FFBF',
  '#008000BF',
  '#800080BF', // Neon Green, Blue, Dark Green, Purple with 75% opacity
  '#39FF1440',
  '#0000FF40',
  '#00800040',
  '#80008040', // Neon Green, Blue, Dark Green, Purple with 25% opacity
];

// Note: only Black and Red are in corporate design with 100%, 50%, 25% opacity
const notAssignedColors = [
  '#000000FF',
  '#808080FF',
  '#FF0000FF',
  '#FFA500FF', // Black, Gray, Red, Orange with 100% opacity
  '#00000080',
  '#80808080',
  '#FF000080',
  '#FFA50080', // Black, Gray, Red, Orange with 50% opacity
  '#000000BF',
  '#808080BF',
  '#FF0000BF',
  '#FFA500BF', // Black, Gray, Red, Orange with 75% opacity
  '#00000040',
  '#80808040',
  '#FF000040',
  '#FFA50040', // Black, Gray, Red, Orange with 25% opacity
];

const CustomTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-45)">
        {payload.value}
      </text>
    </g>
  );
};

const CommoditiesTimeseries: React.FC<CommoditiesTimeseriesProps> = ({
  selectedTimeseriesGroup,
  assignedTimeseries,
  handleChangeTimeseriesToDisplay,
  timeseriesToDisplay,
  adminToken,
  adminRoles,
  client,
  allOptions,
}) => {
  const [timeseriesData, setTimeseriesData] = useState<Timeseries[]>([]);
  const [invalidTimeseries, setInvalidTimeseries] = useState<string[]>([]);
  let assignedIndex = 0;
  let notAssignedIndex = 0;

  useEffect(() => {
    if (timeseriesToDisplay.length === 0) {
      setTimeseriesData([]);
      return;
    }

    const queryUrl = adminRoles?.includes('data_admin')
      ? `${apiUrl}/admin/clients_selected_asm/get_asm_commodities_timeseries`
      : `${apiUrl}/b2zero/${client.id}/get_asm_commodities_timeseries`;
    const authToken = adminRoles?.includes('data_admin') ? adminToken : client.token;

    axios
      .post(
        queryUrl,
        { timeseries: timeseriesToDisplay },
        { headers: { Authorization: `Bearer ${authToken}` } }
      )
      .then((response) => {
        if (response.status === 207) {
          message.warning(response.data.message);
          setInvalidTimeseries(response.data.invalid_columns);
        } else {
          setInvalidTimeseries([]);
        }
        setTimeseriesData(response.data.timeseries);
      })
      .catch((error) => {
        message.error('Error fetching timeseries data: ' + error.message);
      });
  }, [timeseriesToDisplay, adminRoles, client.id, client.token, adminToken]);

  const generateData = (coefficients: Coefficient[]) => {
    return coefficients
      .slice()
      .sort((a, b) => a.year - b.year)
      .map((coef) => ({
        year: coef.year,
        value: coef.value,
      }));
  };

  const combinedData = timeseriesData.reduce(
    (acc, ts) => {
      generateData(ts.coefficients).forEach((dataPoint) => {
        const existing = acc.find((item) => item.year === dataPoint.year);
        if (existing) {
          existing[ts.name] = dataPoint.value;
        } else {
          acc.push({ year: dataPoint.year, [ts.name]: dataPoint.value });
        }
      });
      return acc;
    },
    [] as Array<{ year: number; [key: string]: number }>
  );

  const handleTimeSeriesToDisplayChange = (value: string[]) => {
    handleChangeTimeseriesToDisplay(value); // Probably rename
  };

  return (
    <>
      <Typography.Title level={3}>Zeitreihen: {selectedTimeseriesGroup}</Typography.Title>
      {invalidTimeseries.length > 0 && (
        <Alert
          message="Folgende Zeitreihen existieren nicht, bitte kontaktieren Sie den Support."
          description={invalidTimeseries.map((name) => `"${name}"`).join(', ')}
          type="warning"
          showIcon
        />
      )}
      <Form.Item label="Anzuzeigende Zeitreihen: " style={{ marginBottom: 0 }}>
        <Tooltip title="Wählen Sie die anzuzeigenden Zeitreihen zur Anzeige aus, oder klicken Sie auf eine Zeitreihen Kategorie in der obigen Tabelle.">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Wählen Sie Zeitreihen zur Anzeige aus"
            onChange={handleTimeSeriesToDisplayChange}
            optionFilterProp="children"
            showSearch
            allowClear
            value={timeseriesToDisplay}
          >
            {allOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>
      </Form.Item>
      <ResponsiveContainer
        width="100%"
        height={400}
        style={{ marginTop: '15px', marginBottom: '60px', padding: '0 0 15px 0' }}
      >
        {combinedData.length === 0 ? (
          <Alert
            message="Hier werden die Diagramme angezeigt."
            description={
              <>
                Klicken Sie dafür in "Auswahl Zeitreihen" auf die gewünschte Unterkategorie (Bsp.
                PEF Strom (-) in Primärenergiefaktoren (PEF))
                <br />
                oder wählen Sie die gewünschte Zeitreihe in "Anzuzeigende Zeitreihen" aus.
              </>
            }
            type="info"
          />
        ) : (
          <LineChart data={combinedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="year"
              label={{ value: 'Jahr', position: 'insideBottomRight', offset: -18 }}
              interval={0}
              tick={<CustomTick />}
            />
            <YAxis
              label={{
                value: selectedTimeseriesGroup,
                angle: -90,
                position: 'insideLeft',
                dy: 80,
                offset: 10,
              }}
            />
            <RechartsTooltip />
            <Legend wrapperStyle={{ bottom: -15 }} />
            {timeseriesData.map((ts, index) => {
              const isSelected = assignedTimeseries.some((st) => st.key === ts.name);
              const colors = isSelected ? assignedColors : notAssignedColors;
              const colorIndex = isSelected ? assignedIndex++ : notAssignedIndex++;
              const strokeColor = colors[colorIndex % colors.length];

              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={ts.name}
                  name={`${ts.name}${
                    selectedTimeseriesGroup
                      ? `: [${
                          assignedTimeseries
                            .find((st) => st.key === ts.name)
                            ?.clients.map((c) => `${c.name} (${c.id})`)
                            .join(', ') || 'nicht zugeordnet'
                        }]`
                      : ''
                  }`}
                  stroke={strokeColor}
                />
              );
            })}
          </LineChart>
        )}
      </ResponsiveContainer>
    </>
  );
};

export default CommoditiesTimeseries;
