import React, { useState, useEffect, useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { Card, Alert, Typography, Spin } from 'antd';

interface LineChartCardProps<T> {
  title?: string;
  unit: string;
  data: T[];
  loading?: boolean;
  dataKey: keyof T;
  getLineProps: (item: { id: string; data: T[]; firstPoint: T }) => {
    name: string;
    stroke: string;
  };
  xAxisConfig?: {
    dataKey: keyof T;
    domain?: [number, number];
  };
}

const getGridHeight = (seriesLength: number, containerWidth: number) => {
  if (containerWidth > 1600) {
    if (seriesLength < 12) return '85%';
    else if (seriesLength < 30) return '75%';
    else if (seriesLength > 50) return '85%';
    return '70%';
  } else if (containerWidth > 1200) {
    if (seriesLength < 12) return '80%';
    else if (seriesLength < 30) return '70%';
    else if (seriesLength > 50) return '85%';
    return '60%';
  } else {
    if (containerWidth < 850 || seriesLength > 50) return '85%';
    if (seriesLength < 12) return '75%';
    else if (seriesLength < 30) return '60%';
    return '50%';
  }
};

const LineChartCard = <T extends Record<string, any>>({
  title,
  unit,
  data,
  loading,
  dataKey,
  getLineProps,
  xAxisConfig = { dataKey: 'year', domain: [2020, 2050] },
}: LineChartCardProps<T>) => {
  const dataKeyString = String(dataKey);
  const xAxisKeyString = String(xAxisConfig.dataKey);
  const [series, setSeries] = useState<any[]>([]);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const cardRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (cardRef.current) {
        setContainerWidth(cardRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const gridHeight = useMemo(
    () => getGridHeight(series.length, containerWidth),
    [series.length, containerWidth]
  );
  useEffect(() => {
    // Extract unique pathway IDs and prepare series data
    const pathwayIds = Array.from(new Set(data.map((d) => d.pathwayId)));
    console.log('pathwayIds:', pathwayIds); // Debug log

    // Create series data and sort by first value
    const newSeries = pathwayIds
      .map((id) => {
        const points = data.filter((d) => d.pathwayId === id);
        const { name } = getLineProps({ id, data: points, firstPoint: points[0] });
        const firstValue = Number(points[0]?.[dataKeyString]) || 0;

        return {
          name,
          type: 'line',
          data: points.map((point) => [
            Number(point[xAxisKeyString]),
            Number(point[dataKeyString]),
          ]),
          smooth: false,
          symbol: 'circle',
          symbolSize: 6,
          firstValue,
        };
      })
      .sort((a, b) => b.firstValue - a.firstValue)
      .map(({ firstValue, ...rest }) => rest);

    setSeries(newSeries);
  }, [data, dataKeyString, xAxisKeyString, getLineProps, loading]); // Added loading dependency

  const options = {
    grid: {
      // Add padding for axis labels and legend
      left: '10%',
      right: '5%',
      top: 10,
      height: gridHeight,
    },
    legend: {
      bottom: 0, // Position at bottom
      left: 'center', // Center horizontally
      textStyle: {
        fontSize: 12,
      },
      type: series.length > 50 || containerWidth < 850 ? 'scroll' : 'plain',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        show: true,
        type: 'cross',
        link: { xAxisIndex: 'all' },
        label: {
          show: true,
          formatter: (params: any) => (params.axisDimension === 'y' ? params.value.toFixed(2) : ''),
        },
      },
      formatter: (params: any[]) => {
        return (
          `<b>${params[0].data[0]}</b><br/>` +
          params
            .map(
              (param) =>
                `${param.marker} ${param.seriesName}:&nbsp;&nbsp;<b>${param.data[1].toFixed(2)}</b> ${unit}`
            )
            .join('<br/>')
        );
      },
    },
    xAxis: {
      type: 'value',
      name: 'Jahr',
      nameLocation: 'end',
      min: xAxisConfig.domain?.[0],
      max: xAxisConfig.domain?.[1],
      interval: 1,
      axisLabel: {
        rotate: 45,
        formatter: (value: number) => value.toString(),
        margin: 28, // Add space between axis and labels
        align: 'center', // Center align the rotated labels
      },
    },
    yAxis: {
      type: 'value',
      name: unit,
      nameLocation: 'middle',
      nameGap: 45,
      axisLabel: {
        formatter: (value: number) => value.toFixed(1),
      },
    },
    series,
  };

  return (
    <Card
      ref={cardRef}
      title={
        title && (
          <Typography.Title level={4}>
            {title} {loading && <Spin style={{ marginLeft: 10 }} />}
          </Typography.Title>
        )
      }
      style={{ marginBottom: 24 }}
      loading={loading}
    >
      {data.length === 0 ? (
        <Alert
          message="Keine Daten vorhanden."
          description="Bitte passen Sie die Filter an."
          type="info"
          showIcon
        />
      ) : (
        <ReactECharts
          key={`${data.length}-${series.length}-${data[0]?.pathwayId ?? ''}`}
          option={options}
          style={{ height: 750, width: '100%' }}
        />
      )}
    </Card>
  );
};

export default LineChartCard;
