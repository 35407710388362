import axios from 'axios';
import { message } from 'antd';
import * as Sentry from '@sentry/react';
import { apiUrl } from '../common/url';
import { msalConfig } from '../authConfig';
import { IPublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import { jwtDecode } from 'jwt-decode';

export const renewAdminToken = (
  accessToken: string,
  admin: boolean,
  setAdminRoles: (roles: string[]) => void,
  setAdminToken: (token: string) => void,
  setAuthCompleted: (completed: boolean) => void
) => {
  if (!admin) {
    setAuthCompleted(true);
    return;
  }
  axios
    .post(
      apiUrl + '/auth/get_token',
      { admin: admin },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then((responseGetToken) => {
      setAdminRoles(responseGetToken.data.token_info.roles);
      setAdminToken(responseGetToken.data.token);
    })
    .catch((error) => {
      message.error('Fehler beim Laden der Admin-Informationen: ' + error.message, 3);
      console.error('get_token error', error);
      Sentry.withScope((scope) => {
        scope.setLevel('warning');
        scope.setExtra('hint', 'Fehler beim Laden der Admin-Informationen: ' + error.message);
        Sentry.captureException(error);
      });
    })
    .finally(() => {
      setAuthCompleted(true);
    });
};

export const renewClientToken = (
  clientId: string,
  accessToken: string,
  setClient: (client: any) => void,
  setAuthCompleted: (completed: boolean) => void,
  clients: any[]
) => {
  axios
    .post(
      apiUrl + '/auth/get_token',
      { client_id: clientId },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .then((responseGetToken) => {
      axios
        .get(`${apiUrl}/b2zero/${clientId}/get_token_permissions`, {
          headers: { Authorization: `Bearer ${responseGetToken.data.token}` },
        })
        .then((responseGetTokenPermissions) => {
          setClient({
            id: clientId,
            name: responseGetToken.data.token_info.client.name,
            token: responseGetToken.data.token,
            permissions: [
              ...responseGetTokenPermissions.data.permissions,
              // ...['data_coll_upload', 'get_data_coll_blocked_status'], // add to see data coll upload page, with api not ready yet
            ], // TODO: remove, when api includes required permissions
          });
        })
        .catch((error) => {
          console.log('get_token_permissions error: ', error.response);
          message.error(
            'Fehler beim Laden der Berechtigungen. Status code: ' + error.response.status,
            5
          );
          Sentry.withScope((scope) => {
            scope.setLevel('warning');
            scope.setExtra(
              'hint',
              'Fehler beim Laden der Berechtigungen. Status code: ' + error.response.status
            );
            Sentry.captureException(error);
          });
          if (!clients.some((client) => client.id === clientId)) {
            console.log('User does not have access to client:', clientId);
            message.error('Kein Zugriff auf den ausgewählten Kunden.', 3);
            window.location.href = '/';
            setAuthCompleted(true);
            return;
          }
        })
        .finally(() => {
          setAuthCompleted(true);
        });
    })
    .catch((error) => {
      message.error('Fehler beim Laden der Berechtigungen: ' + error.message, 3);
      Sentry.withScope((scope) => {
        scope.setLevel('warning');
        scope.setExtra('hint', 'Fehler beim Laden der Berechtigungen: ' + error.message);
        Sentry.captureException(error);
      });
      console.log('get_token: ', error.message);
    })
    .finally(() => {
      setAuthCompleted(true);
    });
};

export const logout = (
  instance: IPublicClientApplication,
  inProgress: string,
  setIsLoggingOut: (loggingOut: boolean) => void
) => {
  console.log('Logout initiated, inProgress:', inProgress);
  if (inProgress === 'none' || inProgress === 'startup') {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
      })
      .then(() => {
        sessionStorage.clear();
        setIsLoggingOut(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Logout failed:', error);
        if (error.errorCode === 'endpoints_resolution_error') {
          message.error(
            'Fehler beim Auflösen der Endpunkte. Bitte versuchen Sie es später erneut oder aktualisieren Sie die Seite.'
          );
        } else {
          message.error(
            'Abmeldung fehlgeschlagen. Bitte versuchen Sie es erneut oder aktualisieren Sie die Seite.'
          );
        }
        setIsLoggingOut(false);
      });
  } else {
    message.warning(
      'Eine Interaktion ist derzeit im Gange. Bitte warten Sie, bis sie abgeschlossen ist, oder aktualisieren Sie die Seite.'
    );
    setIsLoggingOut(false);
  }
};

export const decodeToken = (token: string) => {
  try {
    const decoded: any = jwtDecode(token);
    return decoded;
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      scope.setExtra('hint', 'Failed to decode token: ' + error);
      Sentry.captureException(error);
    });
    console.error('Failed to decode token:', error);
    return null;
  }
};

export const willTokenExpireSoon = (token: string) => {
  const decoded = decodeToken(token);
  if (decoded) {
    const currentTime = Date.now() / 1000;
    const expirationTime = decoded.exp;
    const fiveMinutesInSeconds = 5 * 60;
    return expirationTime < currentTime + fiveMinutesInSeconds;
  }
  return true;
};

export const renewTokensIfNeeded = (
  instance: IPublicClientApplication,
  adminToken: string,
  client: any,
  setAdminRoles: (roles: string[]) => void,
  setAdminToken: (token: string) => void,
  setAuthCompleted: (completed: boolean) => void,
  setClient: (client: any) => void,
  clients: any[]
) => {
  const account = instance.getActiveAccount();

  if (!account) {
    console.error('No active account found.');
    return;
  }

  const silentRequest = {
    ...loginRequest,
    account: account,
  };

  // Check if MSAL access token is about to expire: token cache check, token expiry check, token renewal
  instance
    .acquireTokenSilent(silentRequest)
    .then((response) => {
      const msalAccessToken = response.accessToken;

      // Renew admin token if needed
      if (adminToken && adminToken.trim() !== '' && willTokenExpireSoon(adminToken)) {
        renewAdminToken(msalAccessToken, true, setAdminRoles, setAdminToken, setAuthCompleted);
      }

      // Renew client token if needed
      if (
        client &&
        client.token &&
        client.token.trim() !== '' &&
        willTokenExpireSoon(client.token)
      ) {
        renewClientToken(client.id, msalAccessToken, setClient, setAuthCompleted, clients);
      }
    })
    .catch((error) => {
      console.error('Failed to acquire MSAL access token silently:', error);
    });
};
