import { Alert, Button, List, message, Radio, Tooltip, Typography } from 'antd';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../common/url';
import { DataCollRowFragment } from './types/DataCollRowFragment';
import MeasureForm from './MeasureForm';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { DataCollRow, DataCollRowWithFragments } from './types/DataCollRow';
import FacilitySelection from './FacilitySelection';
// import DataCollBlockedStatusDisplay from '../dataCollUpload/DataCollBlockedStatusDisplay';
// import { DataCollBlockedStatus } from '../dataCollUpload/types';

interface ActionPlanningBottomUpProps {
  client: { id: string; name?: string; token: string; permissions: string[] };
}

const ActionPlanningBottomUp: React.FC<ActionPlanningBottomUpProps> = ({ client }) => {
  const [dataCollYear, setDataCollYear] = useState<number>();
  const [dataCollYearTableData, setDataCollYearTableData] = useState<number>();
  const [dataCollRows, setDataCollRows] = useState<DataCollRow[]>([]); // for displaying row specific data for each measure
  const [selectedDataCollEntries, setSelectedDataCollEntries] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [selectedScenario, setSelectedScenario] = useState<string>('A');
  const [measures, setMeasures] = useState<{
    a: DataCollRowFragment[];
    b: DataCollRowFragment[];
    c: DataCollRowFragment[];
  }>({ a: [], b: [], c: [] });
  const [measuresLoading, setMeasuresLoading] = useState<boolean>(false);
  // const [dataCollBlockedStatus, setDataCollBlockedStatus] = useState<DataCollBlockedStatus>();

  useEffect(() => {
    setMeasures({ a: [], b: [], c: [] });
    setMeasuresLoading(false);
  }, [client.id]);

  const createTableData = () => {
    if (!dataCollYear) {
      message.error('Bitte wählen Sie ein Jahr aus', 7);
      return;
    }
    setMeasuresLoading(true);

    let dataCollRowsWithFragments: DataCollRowWithFragments[];

    // request dataCollRow from /data-coll/bottom-up/{key} with year, clientId, and includeDataCollFragments=true
    axios
      .get(`${apiUrl}/b2zero/${client.id}/get_bottom_up_data_coll`, {
        params: {
          clientId: client.id,
          year: dataCollYear,
          keys: selectedKeys.join(','),
          includeDataCollFragments: true,
        },
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        dataCollRowsWithFragments = response.data;

        // this block: create array of DataCollFragments
        const measuresNew: {
          a: DataCollRowFragment[];
          b: DataCollRowFragment[];
          c: DataCollRowFragment[];
        } = { a: [], b: [], c: [] };
        for (const dataCollRowWithFragments of dataCollRowsWithFragments) {
          // loop through the rows
          for (const dataCollRowFragment of dataCollRowWithFragments.dataCollFragments) {
            // loop through the fragments
            measuresNew[dataCollRowFragment.scenario.toLowerCase() as 'a' | 'b' | 'c']?.push(
              dataCollRowFragment
            ); // ignores the Scenario called ABC
          }
        }
        setMeasures(measuresNew);
        setDataCollYearTableData(dataCollYear);
        const dataCollRowsNew = dataCollRowsWithFragments.map((row: DataCollRowWithFragments) => {
          const { dataCollFragments, ...dataCollRow } = row;
          return dataCollRow;
        });
        setDataCollRows(dataCollRowsNew);
        setMeasuresLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data_colls: ', error.message);
        message.error('Fehler beim Abrufen der Maßnahmen: ' + error.message, 7);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Abrufen der Maßnahmen: ' + error.message);
          Sentry.captureException(error);
        });
        setMeasures({ a: [], b: [], c: [] });
        setMeasuresLoading(false);
      });
  };

  const handleChangeMeasureFormItem = (
    key: string,
    scenario: string,
    index: number,
    label: string,
    value: number | string | undefined
  ) => {
    const updatedMeasures = measures[scenario.toLowerCase() as 'a' | 'b' | 'c'].map((measure) => {
      if (measure.key === key && measure.index === index) {
        const updatedData = measure.data.map((formItem) => {
          if (formItem.label === label) {
            return { ...formItem, value };
          }
          return formItem;
        });
        return { ...measure, data: updatedData };
      }
      return measure;
    });
    setMeasures((prevState) => ({ ...prevState, [scenario.toLowerCase()]: updatedMeasures }));
  };

  const handleSaveMeasure = (key: string, scenario: string, index: number) => {
    const measureToSave: DataCollRowFragment | undefined = measures[
      scenario.toLowerCase() as 'a' | 'b' | 'c'
    ].find((measure) => measure.key === key && measure.index === index);
    if (!measureToSave) {
      message.error('Fehler beim Speichern der Maßnahme: Maßnahme nicht gefunden', 7);
      return;
    }
    measureToSave.isLoading = true;
    setMeasures((prevState) => ({
      ...prevState,
      [scenario.toLowerCase()]: prevState[scenario.toLowerCase() as 'a' | 'b' | 'c'].map(
        (measure) => (measure.key === key && measure.index === index ? measureToSave : measure)
      ),
    }));

    const dataCollRow = dataCollRows.find((row) => row.key === key);
    const areaTotal = dataCollRow ? dataCollRow.areaTotal : undefined;

    const { isLoading, ...measureToSaveWithoutLoading } = measureToSave;
    axios
      .post(
        `${apiUrl}/b2zero/${client.id}/update_bottom_up_data_coll`,
        {
          ...measureToSaveWithoutLoading,
          year: dataCollYearTableData,
          clientId: client.id,
          areaTotal: areaTotal,
        } as DataCollRowFragment,
        {
          headers: { Authorization: `Bearer ${client.token}` },
        }
      )
      .then((response) => {
        const updatedMeasure: DataCollRowFragment = {
          ...response.data,
          isLoading: false,
        } as DataCollRowFragment;
        setMeasures((prevState) => ({
          ...prevState,
          [scenario.toLowerCase()]: prevState[scenario.toLowerCase() as 'a' | 'b' | 'c'].map(
            (measure) => (measure.key === key && measure.index === index ? updatedMeasure : measure)
          ),
        }));
        message.success('Maßnahme erfolgreich gespeichert', 5);
      })
      .catch((error) => {
        setMeasures((prevState) => ({
          ...prevState,
          [scenario.toLowerCase()]: prevState[scenario.toLowerCase() as 'a' | 'b' | 'c'].map(
            (measure) =>
              measure.key === key && measure.index === index
                ? { ...measure, isLoading: false }
                : measure
          ),
        }));
        console.error('Error saving measure: ', error.message);
        message.error('Fehler beim Speichern der Maßnahme: ' + error.message, 7);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Speichern der Maßnahme: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  return client === undefined ? (
    <Alert type="warning" message="Please login or select a client." />
  ) : (
    <>
      <h1>{client.name} (Bottom-Up)</h1>
      <FacilitySelection
        client={client}
        dataCollYear={dataCollYear}
        setDataCollYear={setDataCollYear}
        selectedDataCollEntries={selectedDataCollEntries}
        setSelectedDataCollEntries={setSelectedDataCollEntries}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
      />
      <Tooltip title="Maßnahmen konfigurationen Laden. Nicht gespeicherte Änderungen werden überschrieben.">
        <Button type="primary" style={{ marginLeft: '12px' }} onClick={createTableData}>
          Auswahl bestätigen
        </Button>
      </Tooltip>
      {/*
      TODO: re-enable this once the data_coll_blocked_status endpoint is implemented
      <DataCollBlockedStatusDisplay
        dataCollName={`c${client.id}_data_coll${dataCollYear && '_'}${dataCollYear}`}
        dataCollBlockedStatus={dataCollBlockedStatus}
        setDataCollBlockedStatus={setDataCollBlockedStatus}
        client={client}
        styles={{ marginTop: '12px' }}
      /> 
      */}
      <List
        style={{ marginTop: '12px' }}
        header={
          <>
            <Radio.Group
              defaultValue="a"
              buttonStyle="solid"
              style={{ marginBottom: '12px' }}
              value={selectedScenario}
              onChange={(e) => setSelectedScenario(e.target.value)}
            >
              <Radio.Button value="A">A</Radio.Button>
              <Radio.Button value="B">B</Radio.Button>
              <Radio.Button value="C">C</Radio.Button>
            </Radio.Group>
            <Typography.Title level={3} style={{ margin: 0 }}>
              Maßnahmen für Szenario {selectedScenario}
            </Typography.Title>
          </>
        }
        bordered
        loading={measuresLoading}
      >
        {measures[selectedScenario.toLowerCase() as 'a' | 'b' | 'c'].length > 0 &&
          measures[selectedScenario.toLowerCase() as 'a' | 'b' | 'c'].map((measure) => {
            const dataCollRow = dataCollRows.find((row) => row.key === measure.key);
            const areaTotal = dataCollRow ? dataCollRow.areaTotal : undefined;
            const attachedBuildings = dataCollRow ? dataCollRow.attachedBuildings : undefined;

            return (
              <List.Item
                style={{
                  backgroundColor: measure.isBottomUp ? undefined : '#d0d0d0',
                  overflow: 'auto',
                }}
                key={`${measure.key}-${selectedScenario}-${measure.index}`}
              >
                <Typography.Title level={5} style={{ margin: 0 }}>
                  <Tooltip
                    title={attachedBuildings ? `Angeschlossene Gebäude: ${attachedBuildings}` : ''}
                  >
                    {selectedDataCollEntries.find((entry) => entry.includes(`Key: ${measure.key}`))}
                    , {areaTotal && `Fläche Gesamt: ${areaTotal}m²`}, Maßnahme {measure.index}
                    {!measure.isBottomUp && (
                      <>
                        {' '}
                        <Checkbox checked={measure.isBottomUp} disabled>
                          isBottomUp
                        </Checkbox>
                      </>
                    )}
                  </Tooltip>
                </Typography.Title>
                <MeasureForm
                  measure={measure}
                  areaTotal={areaTotal}
                  handleSaveMeasure={handleSaveMeasure}
                  isLoading={measure.isLoading ? true : false}
                  handleChangeMeasureFormItem={handleChangeMeasureFormItem}
                  isBlocked={false}
                  // isBlocked={dataCollBlockedStatus?.isBlocked}
                />
              </List.Item>
            );
          })}
      </List>
    </>
  );
};

export default ActionPlanningBottomUp;
