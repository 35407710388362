import React, { useEffect, useState } from 'react';
import { Form, message, TreeSelect } from 'antd';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { apiUrl } from '../../../common/url';

interface Client {
  id: string;
  name?: string;
}

interface SelectedClient {
  label: string;
  value: number;
}

interface ClientSelectionProps {
  client: { id: string; name?: string };
  clients: Client[];
  handleChangeSelectedClients: (selected: SelectedClient[]) => void;
  adminToken: string;
}

const ClientSelection: React.FC<ClientSelectionProps> = ({
  client,
  clients,
  handleChangeSelectedClients,
  adminToken,
}) => {
  const [clientsOptions, setClientsOptions] = useState<
    { title: string; value: number; children?: any[] }[]
  >([]);
  const [selectedClients, setSelectedClients] = useState<number[]>([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/admin/clients_selected_asm/get_clients_selected_asm_ids`, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((response) => {
        const clientIds = response.data;
        const filteredClients = clients.filter((client) => clientIds.includes(Number(client.id)));
        const clientOptionsNew = filteredClients.map((client) => ({
          title: `${client.name} (${client.id})`,
          value: Number(client.id),
        }));
        setClientsOptions(clientOptionsNew);
        setSelectedClients(
          clientOptionsNew.filter((c) => c.value === Number(client.id)).map((c) => c.value) || []
        );
        if (clientIds.length === 0) {
          message.warning(
            'Die Benötigte kundenspezifische Rolle modellierungsparameter_auswahl_zeitreihen liegt für keinen Kunden vor.' +
              'Bitte wenden Sie sich an den Administrator, um entsprechende Rechte zu erhalten.',
            7
          );
        }
      })
      .catch((error) => {
        message.error('Fehler beim Abruf der ausgewählten Kunden: ' + error.message);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Abrufen der ausgewählten Kunden: ' + error.message);
          Sentry.captureException(error);
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, adminToken]);

  const handleChange = (value: { label: string; value: number }[]) => {
    const selectedClientIds = value.map((user) => user.value);
    setSelectedClients(selectedClientIds);
    handleChangeSelectedClients(value);
  };

  return (
    <Form.Item label="Kunden" style={{ marginBottom: 0 }}>
      <TreeSelect
        treeData={clientsOptions}
        value={selectedClients.map((clientId) => {
          const selectedClient = clients.find((c) => Number(c.id) === clientId);
          return {
            label: `${selectedClient?.name} (${selectedClient?.id})` || 'unknown',
            value: clientId,
          };
        })}
        showSearch
        labelInValue={true}
        onChange={handleChange}
        treeCheckable={true}
        placeholder="Bitte Kunden auswählen"
        allowClear={true}
        filterTreeNode={(inputValue, treeNode) => {
          const label = treeNode.label;
          if (typeof label === 'string') {
            return label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
          }
          return false;
        }}
        style={{ width: '100%', marginBottom: 12 }}
      />
    </Form.Item>
  );
};

export default ClientSelection;
