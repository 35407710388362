import React from 'react';
import { Form, Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface FilterFormItemProps {
  label: string;
  tooltip: React.ReactNode;
  value: any;
  onChange: (value: any) => void;
  options?: { label: string; value: any }[];
  mode?: 'multiple' | undefined;
  defaultValue?: any;
  isLast?: boolean;
  placeholder?: string;
  formatLabel?: (value: any) => string;
}

const FilterFormItem: React.FC<FilterFormItemProps> = ({
  label,
  tooltip,
  value,
  onChange,
  options = [],
  mode,
  defaultValue,
  isLast = false,
  placeholder = `${label} auswählen`,
  formatLabel = (value) => `${value}`,
}) => (
  <Form.Item
    label={
      <Tooltip title={tooltip}>
        <span>
          {label} <InfoCircleOutlined style={{ marginLeft: 4 }} />
        </span>
      </Tooltip>
    }
    labelAlign="left"
    style={{ marginBottom: isLast ? 0 : 8 }}
  >
    <Select
      mode={mode}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      style={{ width: '100%' }}
      options={options?.map((opt) => ({
        label: formatLabel(opt.value),
        value: opt.value,
      }))}
      placeholder={placeholder}
      allowClear
    />
  </Form.Item>
);

export default FilterFormItem;
