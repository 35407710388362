import React, { useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  Table,
  Button,
  Form,
  InputNumber,
  message,
  Select,
  Input,
  Tooltip,
  Modal,
} from 'antd';
import type { TableColumnsType } from 'antd';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { apiUrl } from '../../common/url';
import CO2TargetChart from './CO2TargetChart';

interface SectionType {
  key?: React.Key;
  sectionName: string;
  sectionDescription?: string;
  data: Array<{
    label: string;
    description?: string;
    value: any;
    type: string;
    options?: Array<string | number>;
    validation?: { min?: number; max?: number };
  }>; // when type== selection then options required
}

interface ModelingParametersProps {
  client: { id: string; name?: string; token: string; permissions: string[] };
}

const ModelingParameters: React.FC<ModelingParametersProps> = ({ client }) => {
  const [dataSource, setDataSource] = useState<SectionType[]>([]);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`${apiUrl}/b2zero/${client.id}/get_aux_general_parameters`, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        const dataWithKeys = response.data.sections.map((section: SectionType) => ({
          ...section,
          key: section.sectionName,
        }));
        setDataSource(dataWithKeys);
        setIsTemplate(response.data.is_template);
      })
      .catch((error) => {
        message.error('Fehler beim Abrufen der Eingabeparameter: ' + error.message, 5);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Abrufen der Eingabeparameter: ' + error.message);
          Sentry.captureException(error);
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.id]);

  const handleChangeFormItem = (
    sectionName: string,
    label: string,
    value: number | string | boolean | number[] | string[] | undefined
  ) => {
    setDataSource(
      dataSource.map((section) => {
        if (section.sectionName === sectionName) {
          return {
            ...section,
            data: section.data.map((formItem) =>
              formItem.label === label ? { ...formItem, value } : formItem
            ),
          };
        }
        return section;
      })
    );
  };

  const saveChanges = () => {
    const dataToSend = dataSource.reduce((acc: { [key: string]: any }, { data }) => {
      data.forEach(({ label, value }) => {
        acc[label] = value;
      });
      return acc;
    }, {});

    axios
      .post(`${apiUrl}/b2zero/${client.id}/create_or_update_aux_general_parameters`, dataToSend, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        message.success('Änderungen erfolgreich gespeichert.');
      })
      .catch((error) => {
        message.error('Fehler beim Speichern der Änderungen: ' + error.message, 5);
        console.error('Error while saving data:', error.message);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Error while saving data:' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  const handleDeleteDialog = () => {
    Modal.confirm({
      title: 'Sollen die Eingabeparameter wirklich gelöscht werden?',
      content: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      onOk() {
        deleteModelingParameters();
      },
      onCancel() {
        message.info('Löschen abgebrochen', 3);
      },
    });
  };

  const deleteModelingParameters = () => {
    axios
      .post(`${apiUrl}/b2zero/${client.id}/delete_aux_general_parameters`, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then(() => {
        message.success('Eingabeparameter erfolgreich gelöscht.');
        setDataSource([]);
      })
      .catch((error) => {
        message.error('Fehler beim Löschen der Eingabeparameter: ' + error.message, 5);
        console.error('Error while deleting data:', error.message);
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra('hint', 'Fehler beim Löschen der Eingabeparameter: ' + error.message);
          Sentry.captureException(error);
        });
      });
  };

  const columns: TableColumnsType<SectionType> = [
    { title: 'Parameter Kategorie', dataIndex: 'sectionName', key: 'sectionName', width: '40%' },
    {
      title: 'Beschreibung',
      dataIndex: 'sectionDescription',
      key: 'sectionDescription',
      render: (sectionDescription: string) =>
        sectionDescription ? sectionDescription : 'Keine Beschreibung vorhanden',
    },
  ];

  const getCO2TargetCoefficients = () => {
    const co2TargetSection = dataSource.find((section) => section.sectionName === 'CO2 Target');
    return co2TargetSection
      ? co2TargetSection.data.map((item) => ({ label: item.label, value: item.value }))
      : [];
  };

  return client === undefined ? (
    <div>Please select a client.</div>
  ) : (
    <>
      <h1>Auswahl Eingabeparameter für {client.name}</h1>
      {isTemplate && (
        <Alert
          message="Die Eingabeparameter sind von einem Template übernommen und werden beim Speichern angelegt."
          type="warning"
        />
      )}
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <div style={{ flex: '0 0 30%' }}>
                  <Form layout="horizontal" labelCol={{ span: 16 }} wrapperCol={{ span: 16 }}>
                    {record.data.map((formItem, index) => (
                      <Form.Item
                        key={`${record.sectionName}-${index}`}
                        label={formItem.label}
                        initialValue={formItem.value}
                        tooltip={formItem.description && formItem.description}
                        style={{ lineHeight: '40px' }}
                        // rules={
                        //   formItem.type === 'number'
                        //     ? [
                        //         {
                        //           type: 'number',
                        //           ...(formItem.validation?.min !== undefined && { min: formItem.validation.min }),
                        //           ...(formItem.validation?.max !== undefined && { max: formItem.validation.max }),
                        //           message: `Value must be between ${formItem.validation?.min ?? '-∞'} and ${formItem.validation?.max ?? '∞'}`,
                        //         },
                        //       ]
                        //     : []
                        // } // not applicable here because submit button is outside of forms without html type submit. So instead form validation happens directly on blur
                      >
                        {formItem.type === 'number' && (
                          <InputNumber
                            value={formItem.value}
                            changeOnWheel
                            {...(formItem.validation?.min !== undefined && {
                              min: formItem.validation.min,
                            })}
                            {...(formItem.validation?.max !== undefined && {
                              max: formItem.validation.max,
                            })}
                            onBlur={(e) => {
                              const trimmedValue = e.target.value.trim();
                              if (
                                trimmedValue === '' ||
                                trimmedValue === null ||
                                trimmedValue === undefined
                              ) {
                                return;
                              }
                              const value = Number(trimmedValue);
                              if (isNaN(value)) {
                                message.error(
                                  `Ungültiger Wert für ${formItem.label}: ${trimmedValue}. Bitte geben Sie eine Zahl ein.`,
                                  6
                                );
                                return;
                              }

                              if (
                                formItem.validation?.min !== undefined &&
                                value < formItem.validation.min
                              ) {
                                message.warning(
                                  `Der Wert für ${formItem.label} wurde auf das Minimum von ${formItem.validation.min} gesetzt.`,
                                  6
                                );
                              }
                              if (
                                formItem.validation?.max !== undefined &&
                                value > formItem.validation.max
                              ) {
                                message.warning(
                                  `Der Wert für ${formItem.label} wurde auf das Maximum von ${formItem.validation.max} gesetzt.`,
                                  6
                                );
                              }
                            }}
                            onChange={(value) =>
                              handleChangeFormItem(record.sectionName, formItem.label, value)
                            }
                          />
                        )}
                        {formItem.type === 'selection' && formItem.options && (
                          <Select
                            showSearch
                            style={{ width: 250 }}
                            value={formItem.value}
                            options={formItem.options.map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            popupMatchSelectWidth={true}
                            onChange={(value) =>
                              handleChangeFormItem(record.sectionName, formItem.label, value)
                            }
                            allowClear
                          >
                            {' '}
                            {formItem.options.map((option) => (
                              <Select.Option key={option} value={option}>
                                <Tooltip title={option}>{option}</Tooltip>
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                        {formItem.type === 'multi-selection' && formItem.options && (
                          <Select
                            mode="multiple"
                            style={{ width: 250 }}
                            value={formItem.value}
                            options={formItem.options.map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            popupMatchSelectWidth={true}
                            onChange={(value) =>
                              handleChangeFormItem(record.sectionName, formItem.label, value)
                            }
                            allowClear
                          >
                            {formItem.options.map((option) => (
                              <Select.Option key={option} value={option}>
                                <Tooltip title={option}>{option}</Tooltip>
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                        {(formItem.type === 'text' ||
                          ((formItem.type === 'selection' || formItem.type === 'multi-selection') &&
                            !formItem.options)) && (
                          <Input
                            value={formItem.value}
                            onChange={(e) =>
                              handleChangeFormItem(
                                record.sectionName,
                                formItem.label,
                                e.target.value
                              )
                            }
                            allowClear
                          />
                        )}
                        {formItem.type === 'boolean' && (
                          <Checkbox
                            checked={formItem.value}
                            onChange={(e) =>
                              handleChangeFormItem(
                                record.sectionName,
                                formItem.label,
                                e.target.checked
                              )
                            }
                          />
                        )}
                      </Form.Item>
                    ))}
                  </Form>
                </div>
                {record.sectionName === 'CO2 Target' && (
                  <div style={{ flex: 1, margin: '10px' }}>
                    <CO2TargetChart coefficients={getCO2TargetCoefficients()} />
                  </div>
                )}
              </div>
            );
          },
        }}
        dataSource={dataSource}
      />
      {
        dataSource.length > 0 && (
          <Button type="primary" onClick={saveChanges}>
            Speichern
          </Button>
        ) /* TODO: trigger form validation, or move save to inside the form*/
      }
      {dataSource.length > 0 && (
        <Button danger onClick={handleDeleteDialog} style={{ marginLeft: '10px' }}>
          Eingabeparameter löschen
        </Button>
      )}
    </>
  );
};

export default ModelingParameters;
