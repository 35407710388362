import React, { useEffect, useState } from 'react';
import { Form, Select, message } from 'antd';
import axios from 'axios';
import { apiUrl } from '../../common/url';

interface DataCollSelectionByYearProps {
  dataCollYear?: number;
  setDataCollYear: React.Dispatch<React.SetStateAction<number | undefined>>;
  client: { id: string; name?: string; token: string; permissions: string[] };
}

const DataCollSelectionByYear: React.FC<DataCollSelectionByYearProps> = ({
  dataCollYear,
  setDataCollYear,
  client,
}) => {
  const [dataCollYears, setDataCollYears] = useState<{ label: string; value: number }[]>([]);

  const getDataCollYears = () => {
    axios
      .get(`${apiUrl}/b2zero/${client.id}/get_data_coll_years`, {
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        const years = response.data;
        setDataCollYears(years.map((year: number) => ({ label: year.toString(), value: year })));
        if (years.length > 0) {
          setDataCollYear(Math.max(...years));
        }
      })
      .catch((error) => {
        message.error('Fehler beim Abrufen der verfügbaren Bezugsjahre: ', error.message);
        return [];
      });
  };

  useEffect(() => {
    getDataCollYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item label="Bezugsjahr">
      <Select
        placeholder="Bitte wählen Sie ein Bezugsjahr"
        style={{ width: 200 }}
        onChange={setDataCollYear}
        value={dataCollYear}
        options={dataCollYears}
      />
    </Form.Item>
  );
};

export default DataCollSelectionByYear;
