import React, { useEffect } from 'react';
import { Alert, Button, Typography, message, Modal } from 'antd';
import axios from 'axios';
import { apiUrl } from '../../common/url';
import * as Sentry from '@sentry/react';
import { DataCollBlockedStatus } from './types';
import { formatDate } from '../../common/datetime';
import { useMsal } from '@azure/msal-react';

interface DataCollBlockedStatusDisplayProps {
  dataCollName?: string;
  dataCollBlockedStatus: DataCollBlockedStatus | undefined;
  setDataCollBlockedStatus: React.Dispatch<React.SetStateAction<DataCollBlockedStatus | undefined>>;
  client: { id: string; name?: string; token: string; permissions: string[] };
  adminRoles?: string[];
  adminToken?: string;
  styles?: React.CSSProperties;
}

const DataCollBlockedStatusDisplay: React.FC<DataCollBlockedStatusDisplayProps> = ({
  dataCollName,
  dataCollBlockedStatus,
  setDataCollBlockedStatus,
  client,
  adminRoles,
  adminToken,
  styles,
}) => {
  const { instance } = useMsal();

  const getDataCollBlockedStatus = () => {
    axios
      .get(`${apiUrl}/b2zero/${client.id}/get_data_coll_blocked_status`, {
        params: { data_coll_name: dataCollName },
        headers: { Authorization: `Bearer ${client.token}` },
      })
      .then((response) => {
        const dataCollBlockedStatusNew = response.data as DataCollBlockedStatus;
        dataCollBlockedStatusNew.blocking_user_is_current_user =
          dataCollBlockedStatusNew.blocking_user_id === instance.getActiveAccount()?.localAccountId;
        setDataCollBlockedStatus(dataCollBlockedStatusNew);
      })
      .catch((error) => {
        message.error('Fehler beim Abrufen des data_coll Blockierungs-Status: ' + error.message, 3);

        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          scope.setExtra(
            'hint',
            `Fehler beim Abrufen des Blockierungs-Status für die Tabelle ${dataCollName}: ` +
              error.message
          );
          Sentry.captureException(error);
        });
        return [];
      });
  };

  const handleUnblock = () => {
    Modal.confirm({
      title: 'Wirklich Freigeben?',
      // TODO: better warning, decide if simply removing the blocking state, without dropping open edits.
      // so anyone with the data_coll_uplpload role can continue/work simultaneosly or claim the blockings
      content: `Möchten Sie die Blockierung der Tabelle ${dataCollName} wirklich aufheben?`,
      onOk: () => {
        const url = dataCollBlockedStatus?.blocking_user_is_current_user
          ? `${apiUrl}/b2zero/${client.id}/update_data_coll_blocked_status`
          : adminRoles?.includes('data_admin')
            ? `${apiUrl}/admin/update_data_coll_blocked_status/${client.id}`
            : null;
        const token = dataCollBlockedStatus?.blocking_user_is_current_user
          ? client.token
          : adminToken;
        if (url && token) {
          axios
            .post(
              url,
              {
                data_coll_name: dataCollName,
                isBlocked: false,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then(() => {
              message.success('Die Tabelle wurde erfolgreich zum Bearbeiten freigegeben.');
              setDataCollBlockedStatus(undefined);
            })
            .catch((error) => {
              message.error('Fehler beim Freigeben der Tabelle: ' + error.message, 3);
              Sentry.captureException(error);
            });
        }
      },
      okText: 'Ja, freigeben',
      cancelText: 'Abbrechen',
    });
  };

  useEffect(() => {
    getDataCollBlockedStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {dataCollBlockedStatus && dataCollBlockedStatus.isBlocked && (
        <Alert
          message={`Die Bearbeitung der Tabelle ${dataCollName} wird derzeit ${dataCollBlockedStatus.blocking_user_is_current_user ? 'von Ihnen ' : ''} blockiert!`}
          description={
            <Typography.Paragraph>
              {!dataCollBlockedStatus.blocking_user_is_current_user && (
                <>
                  <b>Blockiert von:</b> {dataCollBlockedStatus.blocking_user_name} (
                  {dataCollBlockedStatus.blocking_user_email})
                  <br />
                </>
              )}
              <b>Blockiert seit:</b> {formatDate(dataCollBlockedStatus.blocked_since)}
            </Typography.Paragraph>
          }
          type={dataCollBlockedStatus.blocking_user_is_current_user ? 'warning' : 'error'}
          action={
            (dataCollBlockedStatus.blocking_user_is_current_user ||
              adminRoles?.includes('data_admin')) && (
              <Button size="small" danger onClick={handleUnblock}>
                Freigeben
              </Button>
            )
          }
          style={styles}
        />
      )}
    </>
  );
};

export default DataCollBlockedStatusDisplay;
